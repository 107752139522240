import React , { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { NotificationsApi } from '@unity/components';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';

export default function NotificationDrawer(props) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 400 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
    >
      <List>
        {props.data.length > 0 ?
          props.data.map((text, index) => (
          <ListItem key={text} disablePadding
          secondaryAction={
            <IconButton aria-label="delete"> 
              <DeleteIcon style= {{color: "red"}} onClick={ () => props.handleDismiss(text.id) }/>
            </IconButton>  
          }
          >
            <ListItemButton>
              <ListItemText 
                primary={text.name}
                secondary = { 
                <>
                  <div dangerouslySetInnerHTML={{__html: text.description}}></div>
                  <span><b>From: </b><i>{text.poster}</i></span>
                </>
                } 
                />
            </ListItemButton>
          </ListItem>
        ))
        : <div style={{display: "flex" , textAlign:"center" , flexDirection: "column"}}>
          <NotificationsOffIcon style={{height:"50px" , width:"50px" , 
            backgroundColor:"lightGrey",
            borderRadius:"15px",
            padding: "10px",
            marginTop:"30px",
            marginLeft:"43%"
      }}/>
      <br></br>
      <p>No notifcations</p>
      </div>
        }

      </List>
    </Box>
  );

  return (
    <div>
      {[''].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
          
          <Drawer
            anchor={props.anchor} //This controls the position of the drawer 
            open={props.open}
            // onClose={toggleDrawer(anchor, false)}
            onClose={props.onClose}
          >
            {list(props.anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}