import React, { useEffect, useState } from "react";

import { Button, InputLabel, CircularProgress, Grid } from "@mui/material";
import { TextField, MenuItem, Select } from "@material-ui/core";

import { PersonnelApi, SalesOpApi } from "@unity/components";
import ReportTable from "./ReportTable";
import Widget from "../Widget";

export default function MarketingReport() {
  const [dateData, setDateData] = useState({
    startDate: null,
    endDate: null,
    person: null,
  });
  const [data, setData] = useState(false);
  const [personnel, setPersonnel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openStats, setOpenStats] = useState({value: 0, count: 0});
  const [lostOpps, setLostOpps] = useState(false);

  const getData = async () => {
    const personnel = await PersonnelApi.getPersonnel();
    setPersonnel(personnel.data);
  };

  const handleSearch = async () => {
    setLoading(true);

    const res = await SalesOpApi.getSalesWon(
      dateData.person.id,
      dateData.startDate,
      dateData.endDate
    );

    setData(res.data);

    const lostRes = await SalesOpApi.getSalesWon(
      dateData.person.id,
      dateData.startDate,
      dateData.endDate,
      true
    );

    setLostOpps(lostRes.data);

    if( dateData.person !== null )
    {
      let payload = { 
        agent_id: dateData.person.id, 
        date_to: dateData.endDate,
        date_from: dateData.startDate  
      };
      const stats = await SalesOpApi.getSalesStats(payload);
  
      setOpenStats(stats.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div className="block">
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-6">
              <InputLabel>Start Date</InputLabel>
              <TextField
                name="startDate"
                type="datetime-local"
                value={dateData.startDate}
                onChange={(e) =>
                  setDateData({
                    ...dateData,
                    [e.target.name]: e.target.value,
                  })
                }
                fullWidth
              />
            </div>
            <div className="form-group col-lg-6">
              <InputLabel>End Date</InputLabel>
              <TextField
                name="endDate"
                type="datetime-local"
                value={dateData.endDate}
                onChange={(e) =>
                  setDateData({
                    ...dateData,
                    [e.target.name]: e.target.value,
                  })
                }
                fullWidth
              />
            </div>
            <div className="form-group col-lg-6">
              <InputLabel>Select Personnal</InputLabel>
              <Select
                value={dateData.person ? dateData.person : ""}
                name="person"
                onChange={(e) =>
                  setDateData({
                    ...dateData,
                    [e.target.name]: e.target.value,
                  })
                }
                fullWidth
              >
                {personnel &&
                  personnel.map((person, key) => {
                    return (
                      <MenuItem key={key} value={person}>
                        {person.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </div>
            <div className="form-group col-lg-6" style={{ paddingTop: "25px" }}>
              {!loading ? (
                <Button
                  variant="contained"
                  onClick={() => {
                    handleSearch();
                  }}
                  fullWidth
                  disabled={loading}
                >
                  Search
                </Button>
              ) : (
                <CircularProgress />
              )}
            </div>
          </div>
        </div>
      </div>
      {data && (
        <>
          <div style={{ margin: "20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Widget
                  count={openStats.count}
                  title={`${dateData.person !== null ? dateData.person.name + "'s " : ""}Open Opportunities Count`}
                  bg="rgb(255,179,204)"
                />
              </Grid>
              <Grid item xs={6}>
                <Widget
                  count={openStats.value}
                  title={`${dateData.person !== null ? dateData.person.name + "'s " : ""}Open Opportunities value`}
                  bg="rgb(255,179,204)"
                />
              </Grid>
            </Grid>
          </div>
          <ReportTable data={data} />
          <ReportTable data={lostOpps} type={false} />
        </>
      )}
    </>
  );
}
