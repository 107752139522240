import React, { useEffect, useState } from "react";
import { AppButtonComponent, SalesOpApi } from "@unity/components";
import {
  InputLabel,
  TextField,
  Switch,
  FormControlLabel,
  Icon,
} from "@material-ui/core";
import ButtonLoader from "../../../common/ButtonLoader";
import Loading from "../../../common/Loading";

export default function ScotsmanForm(props) {
  const [data, setData] = useState(false);

  const handleChange = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const getScotsmanData = async () => {
    const res = await SalesOpApi.getScotsman(props.route.match.params.id);
    setData(res.data);
  };

  useEffect(() => {
    getScotsmanData();
  }, []);

  const handleSave = async () => {
    await SalesOpApi.updateScotsman(data, data.id);
  };

  const sections = [
    {
      name: "Solution",
      description:
        "can we naturally provide a solution that will meet the customer’s needs? i.e. it doesn’t require research and innovation – producing a new product. ",
    },
    {
      name: "Competition",
      description:
        "how are we measuring up against the competition, are we the preferred supplier",
    },
    {
      name: "Only Me",
      description:
        "have we provided a solution that is bespoke so that it perfectly fits what the customer wants?",
    },
    {
      name: "Timescale",
      description:
        "do we have the capacity/availability to deliver the solution in the clients required timeframe?",
    },
    {
      name: "Size",
      description:
        "is the project a good size for us (i.e. it isn’t so big that there is no chance we can do it) false for no (its too big) true for yes",
    },
    {
      name: "Money",
      description:
        "Does the customer have the budget available to make the purchase now? ",
    },
    {
      name: "Authority",
      description:
        "Does the person you are dealing with and have the relationship with have the requisite authority to make the purchase decision.",
    },
    {
      name: "Need",
      description:
        "does the customer need the solution to resolve a problem or current business emergency or is it something they would simply like in an ideal world. If they need it score it as true, if it is something they would like, mark it as false",
    },
  ];

  const calculateResult = () => {
    var score = 0;

    Object.keys(data).map((item, key) => {
      data[item] && (score += 1);
    });

    if (score <= 8) {
      return "You shouldn't be proceeding";
    } else if (score > 8 && score <= 10) {
      return "Decent chance of success, close the gaps to increase likelihood";
    } else {
      return "Good chance of success";
    }
  };

  const calculateFace = () => {
    var score = 0;

    Object.keys(data).map((item, key) => {
      data[item] && (score += 1);
    });

    if (score <= 8) {
      return (
        <Icon
          style={{
            color: "red",
            fontSize: 30,
            position: "absolute",
            marginLeft: 10,
          }}
        >
          mood_bad
        </Icon>
      );
    } else if (score > 8 && score <= 10) {
      return (
        <Icon
          style={{
            color: "orange",
            fontSize: 30,
            position: "absolute",
            marginLeft: 10,
          }}
        >
          mood
        </Icon>
      );
    } else {
      return (
        <Icon
          style={{
            color: "green",
            fontSize: 30,
            position: "absolute",
            marginLeft: 10,
          }}
        >
          mood
        </Icon>
      );
    }
  };

  if (data) {
    return (
      <div className="block">
        <div className="container-fluid">
          <AppButtonComponent
            text="back"
            btnIcon="arrow_back"
            onClick={() => props.handleNavigation(false)}
            style={{ color: "white", background: "green" }}
          />
          <ButtonLoader
            text="save"
            btnIcon="save"
            onClick={() => handleSave()}
          />
          <div className="form-row">
            <div className="form-group col-lg-12">
              <h3>S.C.O.T.S.M.A.N {calculateFace()}</h3>
              <p style={{ marginTop: 20, fontWeight: "bold" }}>
                {calculateResult()}
              </p>
            </div>
          </div>

          {sections.map((item, key) => {
            var n = key + 1;
            var str = `q${n}`;
            const isChecked = data[str];

            return (
              <div className="form-row">
                <div className="form-group col-lg-6">
                  <InputLabel
                    style={{ fontSize: 20, fontWeight: "bold", color: "black" }}
                  >
                    {item.name}
                  </InputLabel>
                  <p>{item.description}</p>
                </div>
                <div className="form-group col-lg-6">
                  <div style={{ paddingLeft: 20 }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isChecked}
                          onChange={(e) =>
                            handleChange(e.target.name, e.target.checked)
                          }
                          name={`q${key + 1}`}
                          color="secondary"
                        />
                      }
                      label={isChecked ? "True" : "False"}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    return <Loading />;
  }
}
