import React, { useContext, useEffect, useState } from "react";
import Widget from "../../modules/dashboard/Widget";
import { CanRead } from "../../services/Permissions";
import Loading from "../../common/Loading";
import { SalesOpApi } from "@unity/components";
import SalesReport from "../../modules/dashboard/report/SalesReport";

export default function Dashboard(route, context) {
  const [data, setData] = useState(false);

  const read = CanRead("dashboard");

  const getReportData = async () => {
    const res = await SalesOpApi.getSalesStats({});
    setData(res.data);
  };

  useEffect(() => {
    getReportData();
  }, []);

  if (data) {
    return (
      <div className="container-fluid">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "0 -5px",
            marginBottom: 15,
          }}
        >
          <div style={{ flex: 1, margin: "0 5px" }}>
            <Widget
              count={data.count}
              title="Open opportunities count"
              bg="rgb(98 218 171)"
            />
          </div>
          <div style={{ flex: 1, margin: "0 5px" }}>
            <Widget
              count={data.value}
              title="Open opportunities value"
              bg="rgb(98 218 171)"
            />
          </div>
        </div>
        <SalesReport />
      </div>
    );
  } else {
    return <Loading />;
  }
}
