import React from "react";
import "./pipeline.css";

export default function SalesPipeLine({ onSelect, active }) {
    return (
        <div style={{ display: 'flex', marginBottom: 30, flexDirection: 'row', position: 'relative' }}>
            <div style={{ flex: 1 }}>
                <div className="pipe-btn-end-stop" style={{ left: "10.4%" }} />
                <div className="pipe-btn-end" style={{ background: "blue", left: "12.4%" }} />
            </div>
            <div style={{ flex: 1 }} className={active === "prospect" ? "pipe-active" : ""} onClick={() => onSelect("prospect")}>
                <button className="pipe-btn" style={{ background: 'blue' }}>prospect</button>
                <div className="pipe-btn-end" style={{ background: "#0095ff" }} />
            </div>
            <div style={{ flex: 1 }} className={active === "qualify" ? "pipe-active" : ""} onClick={() => onSelect("qualify")}>
                <div>
                    <button className="pipe-btn" style={{ background: "#0095ff" }}>qualify</button>
                    <div className="pipe-btn-end" style={{ background: "#00f3ff", left: "37.4%" }} />
                </div>
            </div>
            <div style={{ flex: 1 }} className={active === "call" ? "pipe-active" : ""} onClick={() => onSelect("call")}>
                <button className="pipe-btn" style={{ background: "#00f3ff" }}>call</button>
                <div className="pipe-btn-end" style={{ background: "#d6d620", left: "49.4%" }} />
            </div>
            <div style={{ flex: 1 }} className={active === "proposal" ? "pipe-active" : ""} onClick={() => onSelect("proposal")}>
                <button className="pipe-btn" style={{ background: "#d6d620" }}>proposal</button>
                <div className="pipe-btn-end" style={{ background: "#de0000", left: "62.4%" }} />

            </div>
            <div style={{ flex: 1 }} className={active === "close" ? "pipe-active" : ""} onClick={() => onSelect("close")}>
                <button className="pipe-btn" style={{ background: "#de0000" }}>close</button>
                <div className="pipe-btn-end" style={{ background: "#b72461", left: "74.4%" }} />
            </div>
            <div style={{ flex: 1 }} className={active === "retain" ? "pipe-active" : ""} onClick={() => onSelect("retain")}>
                <button className="pipe-btn" style={{ background: '#b72461' }}>retain</button>
                <div className="pipe-btn-end" style={{ background: "#b72461", left: "86.4%" }} />
            </div>
            <div style={{ flex: 1 }}>
                <div className="pipe-btn-end-stop2" style={{ right: "10.4%" }} />
                <div className="pipe-btn-end" style={{ background: "blue", left: "12.4%" }} />

            </div>
        </div>
    )
}