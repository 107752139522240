import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  AppButtonComponent,
  CostingsApi,
  SalesOpApi,
  DealBuilderApi,
  NotificationsApi
} from "@unity/components";
import DataTable from "../../../common/DataTable";
import Loading from "../../../common/Loading";
import { CanReadAll } from "../../../services/Permissions";
import ModuleButton from "../../../common/ModuleButton";
import ModuleFunctions from "../../dealModules/ModuleFunctions";
import dayjs from "dayjs";
import { Badge, CircularProgress } from "@mui/material";
import NotificationBell from "../../dealModules/NotificationBell";
import NotificationDrawer from "../../dealModules/NotificationDrawer";

export default function DealBuilderForm(props) {
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState({ status: false });
  // const [warning, setWarning] = useState(false);
  // const [warningData, setWarningData] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState(true); // Flag to switch between unpaginated and paginated data!
  const [rowCount, setRowCount] = useState(0); // The total number of rows available from the search!
  const [searchText, setSearchText] = useState("");
  const [fetchTrigger, setFetchTrigger] = useState(0); // This is used to determine if getDocs() needs to be called. The value doesn't matter, just the change!
  const [personnel, setPersonnel] = useState(false);
  const [bus, setBus] = useState(false);
  const [propFocus, setPropFocus] = useState(false);
  const [codes, setCodes] = useState([]);
  const [dataId, setDataId] = useState(false); //Used to recall the notification API for the drawer


  const [notificationDrawerData, setNotificationDrawerData] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);

  console.log("props ", props)
  console.log("deal builder form ", data)

  let history = useHistory();
  const readAll = CanReadAll("sales-op");

  const handleDrawer = async (data) => {
    setDataId(data.id)

    try {
      const res = await NotificationsApi.getTypeNotifications(data.id , "Deal");

      if (!res.data) throw "Data not found"

      //setNotificationDrawerData(res.data)
      filterNotificationList(res.data)
      setOpenDrawer(true)

    } catch (e) {
      console.log(`An error has occured. ${e}`)
    }
  }

  const filterNotificationList = (notif) => {

    let arr = []
    let readBool = false
    
    notif.map( row => {
      if(row.customer_id != null || row.customer_id != undefined){
        if(row.read_by.length < 1 ){
          setNotificationDrawerData( notif )
        }
        else{
          if(row.read_by.includes(props.context.auth.id)){ readBool = true }
        }
        if(!readBool){
            arr.push(row)
        } 
        readBool = false // This resets the read bool at start of the loop
    }} 
    )
    setNotificationDrawerData(arr)
  }


  const handleCloseDrawer = () => {
    setOpenDrawer(false)
    setNotificationDrawerData([])
  }

  const handleDismiss = async (notification_id) => {
    const res = await NotificationsApi.markAsRead(notification_id, false);
    const notifs = await NotificationsApi.getTypeNotifications(dataId , "Deal");

    filterNotificationList(notifs.data);

    try {
      const notifs = await NotificationsApi.getTypeNotifications(data.id , "Deal");
      if (!notifs.data) throw "Data not found"

      //setNotificationDrawerData(res.data)
      filterNotificationList(notifs.data)
    } catch (e) {
      console.log(`An error has occured. ${e}`)
    }
  };

  const getData = async () => {
    const res = await DealBuilderApi.getSalesOppDeals(
      props.route.match.params.id,
      {
        read_all: readAll,
        pagination: pagination,
        page: page + 1,
        per_page: pageSize,
        search_term: searchText,
      }
    );

    if (res.status == 200 || res.status === 201) {
      setData(res.data);
      // if(pagination) {
      //   setRowCount(res.meta.total);
      // }
    } else {
      // the request has failed, load an empty array!
      setData([]);
    }
  };

  const triggerChange = () => {
    setFetchTrigger(fetchTrigger + 1);
  };

  const handleDealPublishToggle = async (obj) => {

    setLoading({ status: true, data: "Selecting Product, Please Wait...." });
    const res = await DealBuilderApi.dealPublishToggle(obj.id, {
      ...obj,
      contact_id: props.salesData.site_contact_id,
    });

    if (res.errors != null) {
      setLoading({ status: true, data: "Successfully updating product" });
      setTimeout(() => {
        triggerChange();
        setLoading({ status: false });
      }, 3000);
    } else {
      if (res.errors) {
        setErrors(res.errors);
        setLoading({
          status: true,
          data: "Validation Errors!, Please Wait....",
        });
        setTimeout(() => {
          triggerChange();
          setLoading({ status: false });
        }, 3000);
      } else {
        setLoading({ status: true, data: res.message });
        setTimeout(() => {
          triggerChange();
          //history.push("/sales-op");
          setLoading({ status: false });
        }, 3000);
      }
    }
  };

  const handleChange = (name, value) => {
    const newData = { ...data };
    newData[name] = value;
    setData(newData);
    setPropFocus(false);
  };

  const personnelChange = (data) => {
    setPersonnel(data);
  };

  const businessChange = (data) => {
    setBus(data);
  };

  const handlePageChange = (page) => {
    setPage(page);

    if (pagination) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleSizeChange = (size) => {
    setPageSize(size);

    if (pagination) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleLinkClick = (event, id, path) => {
    event.preventDefault();
    history.push({
      pathname: `/sales-op/${path}/${id}`,
    });
  };

  const columns = [
    { field: "ref", headerName: "Ref#", flex: 0.5 },
    { field: "name", headerName: "Product Name", flex: 1 },
    {
      field: "sbu_id",
      headerName: "Business Unit",
      minWidth: 200,
      renderCell: (cellValues) => {
        if (cellValues.row.sbu_id) {
          const pc = bus.find((e) => cellValues.row.sbu_id === e.id);
          return pc.name;
        } else {
          return null;
        }
      },
    },
    {
      field: "owner_name",
      headerName: "Deal Owner",
      flex: 0.5,
      renderCell: (cellValues) => {
        if (cellValues.row.owner_id && personnel) {
          const un = personnel.find(
            (x) => x.contact_id == cellValues.row.owner_id
          );
          if (un === undefined) {
            return "User not found";
          }
          return un.name;
        } else {
          return null;
        }
      },
    },
    {
      field: "valid_from",
      headerName: "Valid From",
      minWidth: 200,
      renderCell: (cellValues) => {
        if (cellValues.row.valid_from) {
          return dayjs(cellValues.row.valid_from).format("DD/MM/YYYY");
        } else {
          return <span style={{ color: "red" }}>Valid to date not set</span>;
        }
      },
    },
    {
      field: "valid_to",
      headerName: "Valid To",
      flex: 0.5,
      renderCell: (cellValues) => {
        if (cellValues.row.valid_to) {
          return dayjs(cellValues.row.valid_to).format("DD/MM/YYYY");
        } else {
          return <span style={{ color: "red" }}>Valid to date not set</span>;
        }
      },
    },

    { field: " ", headerName: "Notifications", minWidth: 50,
    renderCell: (cellValues) => {
      return(
        <div onClick={() => handleDrawer(cellValues.row)}>
          <NotificationBell deal_id={cellValues.row.id} context={props.context.auth}/>
        </div>

        )
    }
    },
    
    {
      field: "",
      headerName: "Action",
      minWidth: 250,
      renderCell: (cellValues) => {
        return (
          <div>
            <ModuleButton
              btnIcon="visibility"
              style={{
                background: "#1976d2",
                color: "white"
              }}
              onClick={(event) =>
                handleLinkClick(event, cellValues.row.id, "deal")
              }
              tip="View"
            />

            {
            cellValues.row.owner_id !== props.context.auth.contact ?
              <ModuleButton
                btnIcon="email"
                style={{ backgroundColor: "#189AB4", marginLeft: "10px" }}
                //onClick={() => productChange(cellValues.row)}
                onClick={() => props.openRequestChangeDialogBox(cellValues.row , data)}
                //dealRequestChange
                tip="Request change"
              />
              :
              null
            }

            {
              props.salesData.site_contact_id ? 
                (cellValues.row.published ?
                  <ModuleButton
                    btnIcon="desktop_access_disabled"
                    style={{
                      background: "red",
                      color: "white",
                      marginLeft:"10px"
                    }}
                    onClick={(event) =>
                      handleDealPublishToggle( cellValues.row )
                    }
                    tip="Withold deal"
                />
                :
                <ModuleButton
                    btnIcon="tv"
                    style={{
                      background: "#50C878",
                      color: "white",
                      marginLeft:"10px"
                    }}
                    onClick={(event) =>
                      handleDealPublishToggle( cellValues.row )
                    }
                    tip="Publish deal"
                />)
            : null

            }

          </div>
        );
      },
    },
  ];

  const options = {
    page: page,
    handlePageChange: handlePageChange,
    pageSize: pageSize,
    handleSizeChange: handleSizeChange,
    rowsPerPageOptions: [10, 25, 100],
    rowCount: rowCount,
    autoHeight: true,
    height: 680,
  };

  useEffect(() => {
    getData();
    ModuleFunctions.getPersonnel({ personnelChange: personnelChange });
    ModuleFunctions.getBusinesses({ change: businessChange });
    //getCodes();
  }, [ fetchTrigger ]);


  if (data && bus) {
    return (
      <div className="block">
        <div className="container-fluid">
          <h4 style={{ paddingBottom: "10px" }}>Deals</h4>

          <DataTable
            columns={columns}
            data={data}
            pagination={pagination}
            options={options}
            allowDelete={false}
            handleDeleteArray={false}
            searchText={searchText}
            setSearchText={setSearchText}
            fetchTrigger={fetchTrigger}
            setFetchTrigger={setFetchTrigger}
          />

          <NotificationDrawer
            open={openDrawer}
            onClose={handleCloseDrawer}
            anchor={"right"}
            data={notificationDrawerData}
            handleDismiss={handleDismiss}
          />

        </div>
      </div>
    );
  } else {
    return <Loading />;
  }
}
