import React, { useState, useEffect } from "react";
import ModuleButton from "../../common/ModuleButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { DocumentVaultAPI, LoadingComponent } from "@unity/components";

const SupportingProductDoc = (props) => {
  const [imageLinksArr, setImageLinksArr] = useState(false);

  const fileIcon = (fileType) => {
    const shortFileType = fileType.slice(-12); //This keeps the last 12 characters of the fiel to ensure a document named the as the file type will still show the correct icon

    if (shortFileType.includes("pdf")) {
      return (
        <div style={{ height: "45px", width: "45px", backgroundColor: "red" }}>
          {" "}
          <span style={{ color: "white" }}>
            <strong>PDF</strong>
          </span>
        </div>
      );
    } else if (shortFileType.includes("sheet")) {
      return (
        <div
          style={{ height: "45px", width: "45px", backgroundColor: "#118144" }}
        >
          {" "}
          <span style={{ color: "white" }}>
            <strong>Excel</strong>
          </span>
        </div>
      );
    } else if (shortFileType.includes("presentation")) {
      return (
        <div
          style={{ height: "45px", width: "45px", backgroundColor: "orange" }}
        >
          {" "}
          <span style={{ color: "white" }}>
            <strong>PowerP</strong>
          </span>
        </div>
      );
    } else if (shortFileType.includes("document")) {
      return (
        <div
          style={{ height: "45px", width: "45px", backgroundColor: "#1a60c2" }}
        >
          {" "}
          <span style={{ color: "white" }}>
            <strong>Word</strong>
          </span>
        </div>
      );
    } else if (shortFileType.includes("jpeg")) {
      return (
        <div style={{ height: "45px", width: "45px", backgroundColor: "gray" }}>
          {" "}
          <span style={{ color: "white" }}>
            <strong>JPEG</strong>
          </span>
        </div>
      );
    } else if (shortFileType.includes("png")) {
      return (
        <div style={{ height: "45px", width: "45px", backgroundColor: "gray" }}>
          {" "}
          <span style={{ color: "white" }}>
            <strong>PNG</strong>
          </span>
        </div>
      );
    } else {
      return fileType;
    }
  };

  const handleNewDocumentTab = async (vault_id) => {
    const res = await DocumentVaultAPI.generateTempLink(vault_id);
    window.open(res.data, "_blank");
  };

  const generateImageLink = async () => {
    const linkArrayCopy = [];
    for (let i = 0; i < props.productDocuments.length; i++) {
      const shortFileType = props.productDocuments[i].type.slice(-12);

      if (shortFileType.includes("jpeg") || shortFileType.includes("png")) {
        const res = await DocumentVaultAPI.generateTempLink(
          props.productDocuments[i].vault_id
        );
        linkArrayCopy.push({
          id: i,
          link: res.data,
          vault_id: props.productDocuments[i].vault_id,
        });
      } else {
        linkArrayCopy.push({
          id: i,
          link: null,
          vault_id: props.productDocuments[i].vault_id,
        });
      }
    }
    setImageLinksArr(linkArrayCopy);
  };

  const createImagePreview = (index) => {
    if (imageLinksArr[index].link != null) {
      return (
        <img
          style={{ height: "50px", width: "100px", objectFit: "contain" }}
          src={imageLinksArr[index].link}
        ></img>
      );
    } else {
      return <span>Preview not available</span>;
    }
  };

  useEffect(() => {
    generateImageLink();
  }, []);

  if (imageLinksArr) {
    return (
      <div className="block">
        <div className="container-fluid">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <div style={{ width: "50%" }}>
              <h4>Resources</h4>
              <br></br>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Preview</TableCell>
                      <TableCell>name</TableCell>
                      <TableCell>File Type</TableCell>
                      <TableCell>File Size</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.productDocuments &&
                      props.productDocuments.map((row, key) => (
                        <TableRow
                          //key={row.description}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {createImagePreview(key) || ""}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            {row.name || ""}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {fileIcon(row.type) || ""}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.size || ""}
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            style={{ width: "200px" }}
                          >
                            {row.primary ? (
                              <ModuleButton
                                btnIcon="starsIcon"
                                tip="Current Primary Image"
                                style={{
                                  backgroundColor: "orange",
                                  marginLeft: "10px",
                                }}
                                //onClick={() => handleNewDocumentTab(row.vault_id)}
                              />
                            ) : (
                              <ModuleButton
                                btnIcon="starsIcon"
                                tip="Non-primary image"
                                style={{
                                  backgroundColor: "gray",
                                  marginLeft: "10px",
                                }}
                                //onClick={() => handleNewDocumentTab(row.vault_id)}
                              />
                            )}

                            <ModuleButton
                              btnIcon="visibility"
                              tip="View"
                              style={{
                                backgroundColor: "blue",
                                marginLeft: "10px",
                              }}
                              onClick={() => handleNewDocumentTab(row.vault_id)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <div style={{ width: "50%", paddingLeft: "20px" }}>
              <h4>Schedules</h4>
              <br></br>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>name</TableCell>
                      <TableCell>File Type</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.productSchedules &&
                      props.productSchedules.map((row, key) => (
                        <TableRow
                          //key={row.description}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.document_title || ""}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {fileIcon("pdf") || ""}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <ModuleButton
                              btnIcon="visibility"
                              style={{ backgroundColor: "blue" }}
                              onClick={() => handleNewDocumentTab(row.id)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <LoadingComponent />;
  }
};
export default SupportingProductDoc;
