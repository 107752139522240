import React, { useEffect, useState } from "react";
import SalesOpForm from "./SalesOpForm";
import SalesOpInterfaceCard from "./SalesOpInterfaceCard";
import FinalDealForm from "./sections/FinalDealForm";
import DealBuilderForm from "./sections/DealBuilderForm";
import { AppButtonComponent, CodesApi, SalesOpApi, PersonnelApi, BusinessApi } from "@unity/components";
import ScotsmanForm from "./sections/ScotsmanForm";
import { Link, useHistory } from "react-router-dom";
import "./styles.css";
import { Icon } from "@material-ui/core";
import SalesPipeLine from "./SalesPipeLine";
export default function SalesOpInterface({ type, route, bg, data, setData, context, activeStep, handleActiveStep, openRequestChangeDialogBox, handleNewDeal}) {
  
  //const [activeStep, setActiveStep] = useState(false);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [pipeActive, setPipeActive] = useState(data.stage);
  const [users, setUsers] = useState([]);

  const [companyLoaded, setCompanyLoaded] = useState(false);
  const [companies, setCompanies] = useState({});
  let history = useHistory();
  const customer_id = data.customer_id;

  const steps = [
    {
      id: 1,
      name: "Sales Opportunity Overview",
      icon: "thumbs_up_down_icon",
      progress: 100,
      createPath: false,
    },
    {
      id: 2,
      name: "Final Deal",
      icon: "build",
      progress: 95,
      createPath: false,
    },
    { id: 3, name: "Deal Builder", icon: "work", progress: 22, createPath: false },
    {
      id: 4,
      name: "Negotiate",
      icon: "transfer_within_a_station_icon",
      progress: 25,
      createPath: {
        path: `/activities/negotiation/${customer_id}/create`,
        tag: "Sales Op",
      },
    },
    {
      id: 5,
      name: "Activities",
      icon: "list_alt",
      progress: 22,
      createPath: {
        path: `/activities/communication/${customer_id}/create`,
        tag: "Sales Op",
      },
    },
    {
      id: 6,
      name: "SCOTSMAN",
      icon: "question_answer_icon",
      progress: 97,
      createPath: false,
    },
  ];

  const fetchBusinesses = async (filter = "") => {
    try {
      const res = await BusinessApi.businessIndexFiltered({
        rules: [{field: "customer_name", operator: "LIKE", value: filter !== null ? `%${filter}%` : ""}]
      });
      if(!res.ok) throw "Call Failed";
      setCompanies(res.data.data);
      setCompanyLoaded(true);
    } catch (error) {
      alert(`Failed to fetch businesses - ${error}`);
    }
  }

  const handleNavigation = (id) => {
    if (id === 4) {
      history.push({
        pathname: `/activities/negotiation/lead/${customer_id}/all/index`,
        state: { filter: "Sales Op" },
      });
    }

    if (id === 5) {
      history.push({
        pathname: `/activities/communication/lead/${customer_id}/all/index`,
        state: { filter: "Sales Op" },
      });
    }
    handleActiveStep(id);
  };

  const getBusinessesUnits = async () => {
    const res = await CodesApi.getBusinessUnits();
    setBusinessUnits(res.data);
  };

  const handlePipeSelect = async (stage) => {
    data["stage"] = stage;
    await SalesOpApi.updateSalesOpp(data);
    setPipeActive(stage);
  };

  const handleSalesOpWon = async () => {
    data["won"] = true;
    const res = await SalesOpApi.updateSalesOpp(data);
    setData(res.data);

    if(data?.customer_id == null) return;
    const businessRes = await BusinessApi.businessUpdate(data.customer_id,{
      business: {
        is_client: true
      }
    });
    if(!businessRes?.ok) return alert("Failed to Update Customer");
  };

  const handleSalesOpLost = async () => {
    data["won"] = false;
    const res = await SalesOpApi.updateSalesOpp(data);
    setData(res.data);
  };

  const handleUserChange = (id, name) => {
    const newData = { ...data };

    newData['associated_id'] = id;
    newData['associated_name'] = name;
    setData( newData );
  }

  const getUsers = async () => {
    const res = await PersonnelApi.getPersonnel();
    setUsers(res.data);
  }

  useEffect(() => {
    getBusinessesUnits();
    getUsers();
    fetchBusinesses();
  }, []);

  return (
    <>
      {!activeStep && (
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <SalesPipeLine
                onSelect={(data) => handlePipeSelect(data)}
                active={pipeActive}
              />
            </div>

            <div className="col-lg-12">
              <div className="block">
                <div
                  className="form-row"
                  style={{ alignItems: "center", display: "flex" }}
                >
                  <div className="col-lg-2" style={{ textAlign: "center" }}>
                    <div
                      style={{
                        backgroundImage: "url(" + data.client_logo + ")",
                      }}
                      className="rounded-logo"
                    />
                    <p className="sales-op-index-title">{data.client_name}</p>
                  </div>
                  <div className="col-lg-7">
                    <ul className="sales-op-index-client-details">
                      <li>
                        <Icon>phone</Icon>{" "}
                        <a href={`dial: ${data.contact_phone}`}>
                          {data.contact_phone}
                        </a>
                      </li>
                      <li>
                        <Icon>email</Icon>{" "}
                        <a href={`mailto:${data.contact_email}`}>
                          {data.contact_name}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3">
                    {data.won === null ? (
                      <>
                        <div>
                          <AppButtonComponent
                            onClick={() => handleSalesOpLost()}
                            text="Sales Op Lost"
                            btnIcon="close"
                            style={{ background: "red", color: "white" }}
                          />
                        </div>
                        <div>
                          <AppButtonComponent
                            onClick={() => handleSalesOpWon()}
                            text="Sales Op Won"
                            btnIcon="check"
                            style={{
                              background: "green",
                              color: "white",
                              marginTop: 30,
                            }}
                          />
                        </div>
                      </>
                    ) : data.won ? (
                      <>
                        <div>
                          <AppButtonComponent
                            onClick={() => handleSalesOpLost()}
                            text="Sales Op Lost"
                            btnIcon="close"
                            style={{ background: "red", color: "white" }}
                          />
                        </div>
                        <div className="form-row"></div>
                        <div style={{ marginBottom: 20, marginTop: 20 }}>
                          <Link
                            to={{
                              pathname: `/activities/sales/lead/${customer_id}/on-boarding/create`,
                              state: { tag: "Sales Op" },
                            }}
                          >
                            <AppButtonComponent
                              text="On Boarding Activity"
                              btnIcon="add"
                              style={{ background: "green", color: "white" }}
                            />
                          </Link>
                        </div>

                        <div>
                          <Link
                            to={{
                              pathname: `/activities/sales/lead/${customer_id}/after-sales/create`,
                              state: { tag: "Sales Op" },
                            }}
                          >
                            <AppButtonComponent
                              text="Aftersales Activity"
                              btnIcon="add"
                              style={{ background: "green", color: "white" }}
                            />
                          </Link>
                        </div>
                        <div>
                          <Link
                            to={{
                              pathname: `/activities/sales/leads/${customer_id}/on-boarding/index`,
                            }}
                          >
                            <AppButtonComponent
                              text="View On Boarding"
                              btnIcon="visibility"
                              style={{ background: "blue", color: "white" }}
                            />
                          </Link>
                        </div>
                        <div>
                          <Link
                            to={{
                              pathname: `/activities/sales/leads/${customer_id}/after-sales/index`,
                            }}
                          >
                            <AppButtonComponent
                              text="View AfterSales"
                              btnIcon="visibility"
                              style={{ background: "blue", color: "white" }}
                            />
                          </Link>
                        </div>
                      </>
                    ) : (
                      <div>
                        <AppButtonComponent
                          onClick={() => handleSalesOpWon()}
                          text="Sales Op Won"
                          btnIcon="check"
                          style={{
                            background: "green",
                            color: "white",
                            marginTop: 30,
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {steps.map((item, key) => {
              return (
                <SalesOpInterfaceCard
                  data={item}
                  type={type}
                  key={key}
                  handleNavigation={handleNavigation}
                />
              );
            })}
          </div>
        </div>
      )}
      {activeStep === 1 && (
        <SalesOpForm
          data={data}
          companies={companies}
          companyLoaded={companyLoaded}
          business_units={businessUnits}
          users={users}
          setData={setData}
          handleCompanySelect={() => {}}
          handleChange={() => {}}
          handleUpload={() => {}}
          handleBusinessChange={() => {}}
          handleNavigation={handleNavigation}
          setActiveStep={activeStep}
          handleUserChange={handleUserChange}
          getClients={fetchBusinesses}
          type="edit"
        />
      )}
      {activeStep === 2 && (
        <FinalDealForm handleNavigation={handleNavigation} route={route} salesData={data}/>
      )}
      {activeStep === 3 && (
        <DealBuilderForm
          bg={bg}
          salesData={data}
          handleNavigation={handleNavigation}
          route={route}
          context={context}
          openRequestChangeDialogBox={openRequestChangeDialogBox}
          handleNewDeal={handleNewDeal}
        />
      )}
      {activeStep === 6 && (
        <ScotsmanForm handleNavigation={handleNavigation} route={route} />
      )}
    </>
  );
}
