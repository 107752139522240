import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import { AppButtonComponent, SalesOpApi } from "@unity/components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ButtonLoader from "../../common/ButtonLoader";
import ExtensionOffIcon from "@mui/icons-material/ExtensionOff";
import PageviewIcon from "@material-ui/icons/Pageview";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Button, CircularProgress } from "@mui/material";
import { Typography } from "@mui/material";

import * as config from "../../config";

import { BusinessApi } from "@unity/components";

export default function SalesOpForm(props) {
  const [sites, setSites] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchTimeout, setFetchTimeout] = useState(false);
  const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);
  
  const [selected, setSelected] = useState({
    business: null,
    site: null,
    contact: null,
  });

  const fetchFullBusiness = async (business) => {
    setLoading(true);
    let res = null;
    try {
      res = await BusinessApi.businessShow(business._id);
      if (!res.ok) throw "Call Failed";
      setSites(res.data.sites);

      const tempData = { ...props.data };
      tempData.customer_id = business._id;
      tempData.business_id = business._id;
      tempData.site_id = props.type == "create" ? null : tempData.site_id;
      tempData.site_contact_id = props.type == "create" ? null : tempData.site_contact_id;
      tempData.company_name = res.data.customer_name;
      tempData.sic_code = res.data.sic_code;
      tempData.website = res.data.website;
      tempData.num_employees = isNaN(res.data.number_of_employees)
        ? 0
        : res.data.number_of_employees;
      tempData.industry = res.data.industry;
      props.setData(tempData);

      setContacts([]);
      const tempSelected = { ...selected };
      tempSelected.business = res.data;
      tempSelected.site = props.type == "edit" ? tempSelected.site : null;
      tempSelected.contact = props.type == "edit" ? tempSelected.contact : null;
      setSelected(tempSelected);
      handleSiteContact(tempSelected)
    } catch (error) {
      alert(`Failed to Load Business - ${error}`);
    }
    setLoading(false);
    return res;
  };

  const handleSiteContact = (sel) => {
    let arrayTest = []
    sel.business.sites.map( (site) => {
      arrayTest.concat(site.contacts.map( (index) => {
        return arrayTest.push(index);
      }
      ))
    } )
    setContacts( arrayTest )
  }

  const handleSiteChange = async (site , contact, contactDataToBeSaved) => {

    setLoading(true);
    try {
      let tempData = { ...props.data };
      tempData.site_id = site._id;
      tempData.client_address = `${site.house_no} ${site.street} ${site.postcode}`;
      tempData.client_postcode = site.postcode;
      if(contactDataToBeSaved != null || contactDataToBeSaved != undefined){
        tempData = { ...tempData , ...contactDataToBeSaved }
      }
      await props.setData(tempData);

      if( selected.contact && selected.site != null ){
        if( selected.site._id ==  selected.contact._site_id){
          selected.contact = null
        }
      }

      const tempSelected = { ...selected };
      tempSelected.site = site;
      if(contact != null || contact != undefined){
        tempSelected.contact = contact
      }

      setSelected(tempSelected);

      setContacts(site.contacts);
    } catch (error) {
      alert(`Failed to Switch Sites - ${error}`);
    }
    setLoading(false);
  };

  const handleContactChange = async (contact) => {
    setLoading(true);
    try {
      const tempData = { ...props.data };
      tempData.site_contact_id = contact._id
      tempData.contact_name = `${contact.first_name} ${contact.surname}`;
      tempData.contact_phone = contact.phone;
      tempData.contact_email = contact.email;
      tempData.site_id =  contact._site_id
      await props.setData(tempData);   

      const tempSelected = { ...selected };
      tempSelected.contact = contact;
      setSelected(tempSelected);
      let saveContactData = tempData

      if(selected.contact && selected.site == null || selected.site == undefined){
        const selectedSite = sites.filter( x => {
          if(x._id == tempData.site_id){
            return x
          } 
        })
        if(selectedSite[0] != null || selectedSite[0] != undefined ){ handleSiteChange(selectedSite[0] , tempSelected.contact, saveContactData)  }
      }

    } catch (error) {
      alert(`Failed to Switch Contacts - ${error}`);
    }
    setLoading(false);
  };

  const handleChange = (name, value) => {
    props.setData({ ...props.data, [name]: value });
  };

  const handleSave = async () => {
    await SalesOpApi.updateSalesOpp(props.data, props.data.id);
  };

  const fetchClients = async (val = "") => {
    setLoading(true);
    await props.getClients(val);
    setLoading(false);
  };

  const updateAutocomplete = async (val = "") => {
    setLoadingAutocomplete(true);
    await props.getClients(val);
    setLoadingAutocomplete(false);
  }

  const handleEditLoad = async () => {
    const data = props.data;
    const res = await fetchFullBusiness(data.business_id !== null ? {_id: data.business_id} : { _id: data.customer_id });

    setLoading(true);
    const sites = res.data.sites;
    const selectedSite = sites.filter((site) => site._id == props.data.site_id);
    if(selectedSite !== null && Array.isArray(selectedSite) && selectedSite.length > 0) {
      const tempSelected = {...selected};
      tempSelected.site = selectedSite[0];
      if(props.data.site_contact_id !== null) {
        const contacts = selectedSite[0].contacts;
        if(contacts !== null || Array.isArray(contacts) || contacts.length > 0) {
          const selectedContact = contacts.filter((c) => c._id == props.data.site_contact_id);
          tempSelected.contact = selectedContact[0];
        }
      }
      setSelected(tempSelected);
    }
    
    setLoading(false);
  }

  useEffect(() => {
    if(props.type == "create") return;
    if(props.type == "edit") return handleEditLoad();
  }, []);

  useEffect(() => {
    setLoading(true);
    setLoading(false);
  }, [selected]);

  if (!props.companyLoaded)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "2vh",
        }}
      >
        <CircularProgress />
        <Typography variant="h5" gutterBottom>
          Loading Form Data...
        </Typography>
      </div>
    );

  if (props.comingFromleads)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "2vh",
        }}
      >
        <ExtensionOffIcon size="large" />
        <Typography variant="h5" gutterBottom>
          The direct convert from lead button has been temporarily disabled.<br/>
          Please create the sales op from the sales op form instead.
        </Typography>
      </div>
    );

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "2vh",
        }}
      >
        <CircularProgress />
        <Typography variant="h5" gutterBottom>
          Loading Change in Data....
        </Typography>
      </div>
    );

  return (
    <>
      <div className="block">
        <div className="container-fluid">
          {props.type !== "create" && (
            <>
              <AppButtonComponent
                text="back"
                btnIcon="arrow_back"
                onClick={() => {window.open(`${config.appURL}/sales-op/edit/${props.data.id}`, "_self")}}
                style={{ color: "white", background: "green" }}
              />
              <ButtonLoader
                text="save"
                btnIcon="save"
                onClick={() => handleSave()}
              />
            </>
          )}
          <div className="form-row">
            <div className="form-group col-lg-12">
              <h3>Sales Opportunity</h3>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-4">
              <TextField
                label="Sales Order Ref"
                name="sales_order_ref"
                value={props.data.sales_order_ref}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              ></TextField>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-4">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "0.5vh",
                }}
              >
              {
                props.type !== "create" ? (
                  <>
                    <TextField
                      name="company_name"
                      fullWidth
                      defaultValue={props.data.company_name}
                      disabled={true}
                      size="small"
                    />
                        <Button
                          size="small"
                          onClick={() => {
                            window.open(
                              `${config.appURL}/business/manage/${props.data.customer_id == null ? props.data.business_id : props.data.customer_id}`
                            );
                          }}
                          startIcon={<PageviewIcon />}
                        >
                          View Business
                        </Button>
                        <Button
                          size="small"
                          onClick={() => {
                            fetchFullBusiness(props.data.business_id !== null ? {_id: props.data.business_id} : { _id: props.data.customer_id });
                          }}
                          startIcon={<RefreshIcon />}
                        >
                          Refresh
                        </Button>
                  </>
                ) : (
                  <>
                    <Autocomplete
                        fullWidth
                        defaultValue={selected.business}
                        isOptionEqualToValue={(option, value) => option._id == value._id}
                        disabled={!props.companyLoaded || props.type == "edit"}
                        onChange={(e, val) => {
                          props.handleCompanySelect(val);
                          fetchFullBusiness(val);
                        }}
                        onInputChange={(e, val) => {
                          if(!fetchTimeout) setFetchTimeout(setTimeout(() => {
                            updateAutocomplete(val)
                          }, 1500));
                          else {
                            clearTimeout(fetchTimeout);
                            setFetchTimeout(setTimeout(() => {
                              updateAutocomplete(val);
                            }, 1500));
                          }
                        }}
                        options={Array.isArray(props.companies) && props.companies.length > 0 ? props.companies : []}
                        loading={fetchTimeout}
                        loadingText={"Loading Additional Businesses..."}
                        getOptionLabel={(option) => option.customer_name}
                        renderInput={(params) => (
                          <TextField {...params} label="Company Name" />
                        )}
                      />
                      {
                        fetchTimeout && (
                          <CircularProgress size="small"/>
                        )
                      }
                    {selected.business !== null && (
                      <>
                        <Button
                          size="small"
                          onClick={() => {
                            window.open(
                              `${config.appURL}/business/manage/${selected.business._id}`
                            );
                          }}
                          startIcon={<PageviewIcon />}
                        >
                          View Business
                        </Button>
                        <Button
                          size="small"
                          onClick={() => {
                            fetchClients();
                          }}
                          startIcon={<RefreshIcon />}
                        >
                          Refresh
                        </Button>
                      </>
                    )}
                    </>
                )
              }
              </div>
            </div>
            <div className="form-group col-lg-4">
              <Autocomplete
                fullWidth
                value={selected.site}
                isOptionEqualToValue={(option, value) => option._id == value._id}
                disabled={!Array.isArray(sites) || sites.length <= 0}
                onChange={(e, val) => handleSiteChange(val)}
                options={sites}
                getOptionLabel={(option) =>
                  `${option.house_no} ${option.street}`
                }
                renderInput={(params) => (
                  <TextField {...params} label="Site Name" />
                )}
              />
            </div>
            <div className="form-group col-lg-4">
              <Autocomplete
                fullWidth
                value={selected.contact}
                isOptionEqualToValue={(option, value) => option._id == value._id}
                disabled={!Array.isArray(contacts) || contacts.length <= 0}
                onChange={(e, val) => handleContactChange(val)}
                options={contacts}
                getOptionLabel={(option) =>
                  `${option.first_name} ${option.surname}`
                }
                renderInput={(params) => (
                  <TextField {...params} label="Contact Name" />
                )}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-12">
              <TextField
                label="Opportunity Overview"
                name="sales_opp_overview"
                multiline
                value={props.data.sales_opp_overview}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              ></TextField>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-3">
              <TextField
                label="Company Name"
                name="company_name"
                disabled={true}
                defaultValue={
                  props.data.company_name ? props.data.company_name : ""
                }
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              ></TextField>
            </div>
            <div className="form-group col-lg-3">
              <TextField
                label="Contact Name"
                name="contact_name"
                disabled={true}
                defaultValue={
                  props.data.contact_name ? props.data.contact_name : ""
                }
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              ></TextField>
            </div>
            <div className="form-group col-lg-3">
              <TextField
                label="Contact Email"
                name="contact_email"
                disabled={true}
                defaultValue={
                  props.data.contact_email ? props.data.contact_email : ""
                }
                fullWidth={true}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              ></TextField>
            </div>
            <div className="form-group col-lg-3">
              <TextField
                label="Contact Phone"
                name="contact_phone"
                disabled={true}
                defaultValue={
                  props.data.contact_phone ? props.data.contact_phone : ""
                }
                fullWidth={true}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              ></TextField>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-lg-4">
              <TextField
                label="Client Address"
                defaultValue={props.data.client_address}
                name="client_address"
                disabled={true}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              ></TextField>
            </div>
            <div className="form-group col-lg-4">
              <TextField
                label="Client Postcode"
                defaultValue={props.data.client_postcode}
                name="client_postcode"
                disabled={true}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              ></TextField>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-lg-4">
              <TextField
                label="Sic Code"
                disabled={true}
                defaultValue={props.data.sic_code ? props.data.sic_code : ""}
                name="sic_code"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              ></TextField>
            </div>
            <div className="form-group col-lg-4">
              <TextField
                label="Website"
                disabled={true}
                defaultValue={props.data.website}
                name="website"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              ></TextField>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-4">
              <InputLabel></InputLabel>
              <TextField
                label="Industry"
                disabled={true}
                defaultValue={props.data.industry}
                name="industry"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              ></TextField>
            </div>
            <div className="form-group col-lg-4">
              <InputLabel></InputLabel>
              <TextField
                label="Number of Employees"
                disabled={true}
                defaultValue={props.data.num_employees}
                name="num_employees"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
              ></TextField>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-lg-4">
              <InputLabel style={{ fontSize: 12, marginBottom: 4 }}>
                Business Unit
              </InputLabel>
              <Select
                fullWidth
                name="business_unit"
                value={props.data.business_unit ? props.data.business_unit : ""}
                onChange={(e) => props.handleBusinessChange(e.target.value)}
              >
                {props.business_units &&
                  props.business_units.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </div>
            <div className="form-group col-lg-4">
              <InputLabel style={{ fontSize: 12, marginBottom: 4 }}>
                Product Categories
              </InputLabel>
              {props.data.business_unit ? (
                <Select
                  name="product_category"
                  fullWidth
                  value={
                    props.data.product_category
                      ? props.data.product_category
                      : ""
                  }
                  onChange={(e) =>
                    props.handleChange(e.target.name, e.target.value)
                  }
                >
                  {props.productCategories &&
                    props.productCategories.map((item, key) => {
                      return (
                        <MenuItem key={key} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              ) : (
                <Select value={0} name="product_category" fullWidth>
                  <MenuItem value={0}>Please choose a business unit</MenuItem>
                </Select>
              )}
            </div>
            <div className="form-group col-lg-4">
              <InputLabel style={{ fontSize: 12, marginBottom: 4 }}>
                Allocate To
              </InputLabel>
              <Select
                fullWidth
                name="allocated"
                value={props.data.associated_id}
                onChange={(e) => {
                  const username = props.users.find((user) => {
                    return user.id == e.target.value;
                  });
                  props.handleUserChange(e.target.value, username.name);
                }}
              >
                {props.users &&
                  props.users.map((item, key) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </div>
          </div>
        </div>
      </div>

      <div className="block">
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-12">
              <h3>Notes</h3>
            </div>

            <div className="form-group col-lg-12">
              <TextField
                label="Notes"
                value={props.data.notes}
                name="notes"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                fullWidth={true}
                multiline
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
