import React, { useState, useEffect } from "react";
import ModuleButton from "../../common/ModuleButton";
import Dialog from "@mui/material/Dialog";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ProductLineOverview from "./ProductLineOverview";
import { Button, DialogActions, DialogContent, TextField } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
//import DatePicker from "../common/DatePicker";
import { DocumentVaultAPI } from "@unity/components";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const FullScreenDialogBox = (props) => {

  const handleInputChange = async (event, index) => {
    const files = event.target.files;
    let reader = new FileReader();
    for (let i = 0; i < files.length; i++) {
      reader.readAsDataURL(files[i]);
      reader.onload = (e) => {
        storeDocument(
          {
            base64: e.target.result,
            name: files[i].name,
            type: files[i].type,
            size: files[i].size,
          },
          index
        );
      };
    }
  };

  const storeDocument = async (obj, lineIndex) => {
    const up = await DocumentVaultAPI.storeDocument({
      documents: [obj],
      application_ref: props.context.auth.app_uuid,
      public: 0,
    });
    props.handleFileUploadedData(lineIndex, up);
  };

  return (
    <Dialog
      open={props.openFullBox}
      onClose={props.closeFullBox}
      fullWidth={true}
      maxWidth="90vw"
    >
      <DialogContent>
        <ProductLineOverview
          fullBoxInfo={props.data}
          getChargeData={props.getChargeData}
          getPeriodicData={props.getPeriodicData}
          uneditable={props.uneditable}
        />

        {props.data.dynamic ? (
          <>
            <div className="block">
              <div className="container-fluid">
                <div style={{ width: "100%" }}>
                  <h4>Outlines</h4>
                  <br></br>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Material Name</TableCell>
                          <TableCell>Supplier Name</TableCell>
                          {props.type == "builder" ? null : (
                            <TableCell align="right">Unit Cost</TableCell>
                          )}

                          {props.type == "builder" ? null : (
                            <TableCell align="right">Markup</TableCell>
                          )}

                          <TableCell align="right">Unit Price</TableCell>
                          <TableCell align="right">Min term</TableCell>
                          <TableCell align="right">Valid from</TableCell>
                          <TableCell align="right">Valid to</TableCell>
                          <TableCell align="right">Status</TableCell>
                          <TableCell align="left">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {props.data.options &&
                          props.data.options.map(
                            (row, key) =>
                              !row.approved && (
                                <TableRow
                                  //key={row.description}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {row.material_name}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {row.supplier_name}
                                  </TableCell>
                                  

                                  {props.type == "builder" ? null : (
                                    <TableCell align="right">
                                      <TextField
                                        label=""
                                        name="material_cost"
                                        type="number"
                                        onChange={(e) =>
                                          props.calculateMarkup(e, key)
                                        }
                                        defaultValue={row.material_cost || ""}
                                        size="small"
                                        fullWidth
                                        InputProps={{
                                          readOnly: props.uneditable,
                                        }}
                                        error={
                                          props.errors &&
                                          props.errors[
                                            `lines.${index}.material_cost`
                                          ]
                                        }
                                        helperText={
                                          props.errors &&
                                          props.errors[
                                            `lines.${index}.material_cost`
                                          ]
                                            ? props.errors[
                                                `lines.${index}.material_cost`
                                              ]
                                            : ""
                                        }
                                      ></TextField>
                                    </TableCell>
                                  )}

                                  {props.type == "builder" ? null : (
                                    <TableCell align="right">
                                      <TextField
                                        label=""
                                        name="markup"
                                        type="number"
                                        onChange={(e) =>
                                          props.calculateMarkup(e, key)
                                        }
                                        defaultValue={row.material_cost || ""}
                                        size="small"
                                        fullWidth
                                        InputProps={{ readOnly: true }}
                                      ></TextField>
                                    </TableCell>
                                  )}

                                  <TableCell align="right">
                                    <TextField
                                      label=""
                                      name="unit_price"
                                      type="number"
                                      onChange={(e) =>
                                        props.handleDynamicLine(e, key)
                                      }
                                      value={row.unit_price || ""}
                                      size="small"
                                      fullWidth
                                      //InputProps={{ readOnly: true }}
                                    ></TextField>
                                  </TableCell>

                                  <TableCell align="right">
                                    <TextField
                                      label=""
                                      name="min_term"
                                      type="number"
                                      defaultValue={row.min_term || ""}
                                      size="small"
                                      onChange={(e) =>
                                        props.handleDynamicLine(e, key)
                                      }
                                      fullWidth
                                      InputProps={{
                                        readOnly: props.uneditable,
                                      }}
                                    ></TextField>
                                  </TableCell>

                                  <TableCell align="right">
                                    <TextField
                                        label=""
                                        name="valid_from"
                                        value={ new Date(row.valid_from).toLocaleDateString() || ""}
                                        size="small"
                                        fullWidth
                                        InputProps={{ readOnly: true }}
                                      ></TextField>
                                  </TableCell>
                                  <TableCell align="right">
                                    <TextField
                                          label=""
                                          name="valid_to"
                                          value={ new Date(row.valid_to).toLocaleDateString() || ""}
                                          size="small"
                                          fullWidth
                                          InputProps={{ readOnly: true }}
                                        ></TextField>
                                  </TableCell>

                                  <TableCell align="right">
                                    {row.approved ? (
                                      <CheckCircleIcon color="success" />
                                    ) : (
                                      <HelpIcon />
                                    )}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ width: "200px" }}
                                  >
                                    {props.type == "builder" ||
                                    props.uneditable ? null : (
                                      <Button
                                        variant="contained"
                                        component="label"
                                        onChange={(evt) =>
                                          handleInputChange(evt, key)
                                        }
                                      >
                                        <UploadFileIcon />
                                        <input type="file" hidden={true} />
                                      </Button>
                                    )}

                                    <ModuleButton
                                      btnIcon="visibility"
                                      style={{
                                        backgroundColor: "blue",
                                        marginLeft: "10px",
                                      }}
                                      onClick={() =>
                                        props.handleOpenOptionalDocumentDialogBox(
                                          key
                                        )
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                              )
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </DialogContent>

      <DialogActions>
      <Button 
                    onClick={props.closeFullBox}
                    style={{ backgroundColor: props.context.theme.sidebar.background, color: "white", padding: "0.5rem 2.5rem 0.5rem 2.5rem" }}
                >Done</Button>
      </DialogActions>
    </Dialog>
  );
};
export default FullScreenDialogBox;
