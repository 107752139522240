import { CircularProgress, Icon } from "@material-ui/core";
import React from "react";
import { AppButtonComponent } from "@unity/components";
import { Link } from "react-router-dom";
export default function SalesOpInterfaceCard({ data, type, handleNavigation }) {
  const renderColour = (val) => {
    if (val < 50) {
      return "#dc3545";
    } else if (val > 50 && val <= 99) {
      return "orange";
    } else {
      return "green";
    }
  };

  return (
    <div className="col-lg-4">
      <div className="block sales-op-card-container">
        <div style={{ textAlign: "center", fontSize: 20 }}>
          <div style={{ padding: 20 }}>
            <Icon>{data.icon}</Icon>
            <p>{data.name}</p>
            {/*             <div>
              <CircularProgress
                style={{ color: renderColour(data.progress) }}
                size={50}
                variant="determinate"
                value={data.progress}
              />
              <div className="sales-op-progress-count">{data.progress}</div>
            </div> */}
          </div>

          {type === "view" && (
            <AppButtonComponent
              onClick={() => handleNavigation(data.id)}
              text="view"
              btnIcon="visibility"
              style={{
                background: "#092f57",
                color: "white",
                justifyContent: "center",
                width: "100%",
                marginTop: 10,
              }}
            />
          )}
          {type === "edit" && (
            <>
              <AppButtonComponent
                onClick={() => handleNavigation(data.id)}
                text="edit"
                btnIcon="edit"
                style={{
                  background: "#092f57",
                  color: "white",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: 10,
                }}
              />
              {data.createPath && (
                <Link
                  to={{
                    pathname: data.createPath.path,
                    state: { tag: data.createPath.tag },
                  }}
                >
                  <AppButtonComponent
                    onClick={() => handleNavigation(data.id)}
                    text="create"
                    btnIcon="add"
                    style={{
                      background: "green",
                      color: "white",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: 10,
                    }}
                  />
                </Link>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
