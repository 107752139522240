import React, { useEffect, useState } from "react";

import ForecastTable from "../../modules/SalesOp/components/ForecastTable";
import { AppHeaderComponent } from "@unity/components";
import ApiLoader from "../../common/ApiLoader";

import { SalesOpApi, PersonnelApi } from "@unity/components";
import Loading from "../../common/Loading";

import { useHistory } from "react-router";
import { Button, Select, MenuItem } from "@material-ui/core";

export default function ForecastIndex({ route, context }) {
  const [data, setData] = useState();

  const [testData, setTestData] = useState();
  const [loading, setLoading] = useState({ status: false });
  const [changePerson, setChangePerson] = useState(false);
  const [personnel, setPersonnel] = useState(false);
  const [selectedPersonnel, setSelectedPersonnel] = useState(0);

  const [rowsExpanded, setRowsExpanded] = useState(false);

  let history = useHistory();

  const todaysDate = new Date().toISOString().split("T")[0];

  const getData = async () => {
    const res = await SalesOpApi.getSalesOppsByOpen();
    res.data.map((e) => {
      e["chance_won"] = 100;
      e["proposal_value"] = 0;
      e["close_date"] = todaysDate;

      e.proposals.map((e2) => {
        e2["chance_won"] = 100;
        e2["close_date"] = todaysDate;
      });
    });

    setData(res.data);
    setTestData(res.data);

    const res2 = await PersonnelApi.getPersonnel();
    setPersonnel(res2.data);
  };

  const saveData = async () => {
    const tempData = { ...testData };

    setLoading({ status: true, data: "Loading please wait..." });

    if (rowsExpanded) {
      rowsExpanded.map((row) => {
        tempData[row.index]["proposal_value"] = 1;
      });
    }

    const x = await SalesOpApi.saveGenerateForecast(tempData);

    setLoading({ status: true, data: "Save Success Please Wait..." });
    setTimeout(() => {
      history.push(`/forecast/view/${x.data.id}`);
    }, 4000);
  };

  const handleProposalsChange = (key, name, value, key2) => {
    const newData = { ...testData };
    if (name == "chance_won") value = validateChance(value);
    newData[key]["proposals"][key2][name] = value;
    //newData[key]["proposal_value"] = 1;
    setTestData(newData);
  };

  const validateChance = (value) => {
    if (value > 100) return 100;
    else if (value < 0) return 0;
    else return value;
  };

  const handleChange = (dataIndex, name, value) => {
    if (name == "chance_won") value = validateChance(value);
    const tempData = { ...data };
    tempData[dataIndex]["proposal_value"] = 0;
    tempData[dataIndex][name] = value;
    setTestData(tempData);
  };

  const getForeCast = async (id) => {
    setSelectedPersonnel(id);
    const newObj = {
      agent_id: id,
    };

    const res = await SalesOpApi.getForecastByFilter(newObj);

    res.data.map((e) => {
      e["chance_won"] = 100;
      e["proposal_value"] = 0;
      e["close_date"] = todaysDate;

      e.proposals.map((e2) => {
        e2["chance_won"] = 100;
        e2["close_date"] = todaysDate;
      });
    });

    setData(res.data);
    setTestData(res.data);
  };

  const setShowAllButton = () => {
    if (changePerson) getData();
    changePerson ? setChangePerson(false) : setChangePerson(true);
  };

  useEffect(() => {
    getData();
  }, []);

  if (data) {
    return (
      <>
        <AppHeaderComponent
          context={context}
          saveBtn={saveData}
          theme={context.theme}
          name="forecast"
        />

        <ApiLoader status={loading.status} data={loading.data} />

        <div style={{ paddingTop: 200 }}>
          <div>
            <Button
              style={{ marginBottom: "5px", marginRight: "5px" }}
              variant="contained"
              onClick={() => {
                setShowAllButton();
              }}
            >
              Show All
            </Button>

            {changePerson && (
              <span>
                <Select
                  value={selectedPersonnel}
                  onChange={(e) => getForeCast(e.target.value)}
                >
                  {personnel &&
                    personnel.map((item, key) => {
                      return (
                        <MenuItem key={key} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </span>
            )}
          </div>
          <ForecastTable
            data={data}
            handleProposalsChange={handleProposalsChange}
            handleChange={handleChange}
            setExpandedRows={setRowsExpanded}
          />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
