import React from "react";
//import { Chart, Interval } from "bizcharts";

export default function ForecastChart(props) {
  const data = props.data;

  let dataArray = [];

  data.items.map((e) => {
    dataArray.push({ month: e.month_name, value: parseFloat(e.value) });
  });

  return (
    <h1></h1>
    // <Chart padding={[10, 20, 50, 40]} autoFit height={300} data={dataArray}>
    //   <Interval position="month*value" color="month" />
    // </Chart>
  );
}
