import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AuthContext from "./services/AuthContext";
import SalesOp from "./pages/index";
import SalesOpEdit from "./pages/edit";
import SalesOpView from "./pages/view";
import SalesOpCreate from "./pages/create";
import "./sales-op.css";
import Dashboard from "./pages/dashboard/index";

import ForecastIndex from "./pages/forecast";
import GenerateForecast from "./pages/forecast/generate";
import SalesOpDeal from "./pages/deal";
import SalesOpProduct from "./pages/product";
export default function Root(props) {
  let context = { ...props };
  let history = useHistory();

  return (
    <AuthContext.Provider
      value={{
        ...props,
      }}
    >
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route path="/location" exact component={Location} />
                    {props.auth.access["sales-op"]["A"] &&
                      props.auth.access["sales-op"]["R"] && (
                        <Route
                          path="/dashboard/index"
                          exact
                          component={(route) => (
                            <Dashboard
                              route={route}
                              history={history}
                              context={context}
                            />
                          )}
                        />
                      )}
                    {props.auth.access["sales-op"]["A"] &&
                      props.auth.access["sales-op"]["R"] && (
                        <Route
                          path="/sales-op/index"
                          exact
                          component={(route) => (
                            <SalesOp
                              route={route}
                              history={history}
                              context={context}
                            />
                          )}
                        />
                      )}
                    {props.auth.access["sales-op"]["A"] &&
                      props.auth.access["sales-op"]["R"] && (
                        <Route
                          path="/sales-op/view/:id"
                          exact
                          component={(route) => (
                            <SalesOpView
                              route={route}
                              history={history}
                              context={context}
                            />
                          )}
                        />
                      )}
                    {props.auth.access["sales-op"]["A"] &&
                      props.auth.access["sales-op"]["U"] && (
                        <Route
                          path="/sales-op/edit/:id"
                          exact
                          component={(route) => (
                            <SalesOpEdit
                              route={route}
                              context={context}
                              history={history}
                            />
                          )}
                        />
                      )}
                    {props.auth.access["sales-op"]["A"] &&
                      props.auth.access["sales-op"]["C"] && (
                        <Route
                          path="/sales-op/create"
                          exact
                          component={(route) => (
                            <SalesOpCreate
                              route={route}
                              history={history}
                              context={context}
                            />
                          )}
                        />
                      )}
                    {props.auth.access["sales-op"]["A"] &&
                      props.auth.access["sales-op"]["C"] && (
                        <Route
                          path="/forecast/index"
                          exact
                          component={(route) => (
                            <ForecastIndex
                              route={route}
                              history={history}
                              context={context}
                            />
                          )}
                        />
                      )}
                    {props.auth.access["sales-op"]["A"] &&
                      props.auth.access["sales-op"]["C"] && (
                        <Route
                          path="/forecast/view/:id"
                          exact
                          component={(route) => (
                            <GenerateForecast
                              route={route}
                              history={history}
                              context={context}
                            />
                          )}
                        />
                      )}
                    {props.auth.access["sales-op"]["A"] &&
                      props.auth.access["sales-op"]["C"] && (
                        <Route
                          path="/sales-op/deal/:id"
                          exact
                          component={(route) => (
                            <SalesOpDeal
                              route={route}
                              history={history}
                              context={context}
                            />
                          )}
                        />
                      )}
                    {props.auth.access["sales-op"]["A"] &&
                      props.auth.access["sales-op"]["C"] && (
                        <Route
                          path="/sales-op/product/:id"
                          exact
                          component={(route) => (
                            <SalesOpProduct
                              route={route}
                              history={history}
                              context={context}
                            />
                          )}
                        />
                      )}
                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
}
