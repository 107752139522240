import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import FileBase64 from "react-file-base64";

//form sets init state
//update creates array of updated values

export default function LeadsForm(props) {

    return (
        <>
            <div className="block">
                <div className="container-fluid">
                    <div className="form-row">
                        <div className="form-group col-lg-12">
                            <h3>Lead Information</h3>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-lg-4">
                            <TextField
                                label="Subject"
                                name="subject"
                                value={props.data.subject}
                                onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                fullWidth={true}
                            ></TextField>
                        </div>
                        <div className="form-group col-lg-4">
                            <TextField
                                label="Reference Number"
                                name="ref_number"
                                value={props.data.reference_number}
                                onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                fullWidth={true}
                            ></TextField>
                        </div>
                        <div className="form-group col-lg-4">
                            <TextField
                                label="Company Name"
                                name="company_name"
                                value={props.data.company_name}
                                fullWidth={true}
                                onChange={(e) => props.handleChange(e.target.name, e.target.value)}

                            ></TextField>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-lg-4">
                            <TextField
                                label="Contact Name"
                                value={props.data.contact_name}
                                name="contact_name"
                                onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                fullWidth={true}
                            ></TextField>
                        </div>
                        <div className="form-group col-lg-4">
                            <TextField
                                label="Contact Email"
                                value={props.data.contact_email}
                                name="contact_email"
                                onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                fullWidth={true}
                            ></TextField>
                        </div>
                        <div className="form-group col-lg-4">
                            <TextField
                                label="Contact Phone"
                                value={props.data.contact_phone}
                                name="contact_phone"
                                onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                fullWidth={true}
                            ></TextField>
                        </div>

                    </div>
                    <div className="form-row">

                        <div className="form-group col-lg-4">
                            <InputLabel>Associated Business Unit</InputLabel>
                            <Select fullWidth name="business_unit" value={props.data.business_unit} onChange={(e) => props.handleBusinessChange(e.target.value)}>
                                {props.businesses && props.businesses.map((item, key) => {
                                    return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                })}
                            </Select>
                        </div>

                        <div className="form-group col-lg-4">
                            <InputLabel>Associated Product Category</InputLabel>
                            {props.data.business_unit ?

                                <Select name="product_category" fullWidth value={props.data.product_category} onChange={(e) => props.handleChange(e.target.name, e.target.value)}>
                                    {props.productCategories && props.productCategories.map((item, key) => {
                                        return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                    })}
                                </Select>
                                :
                                <Select value={0} name="product_category" fullWidth>
                                    <MenuItem value={0}>Please choose a business unit</MenuItem>
                                </Select>
                            }

                        </div>

                        <div className="form-group col-lg-4">
                            <InputLabel>Lead Source</InputLabel>
                            <Select name="lead_source" fullWidth>

                            </Select>
                        </div>

                        <div className="form-group col-lg-4">
                            <TextField
                                label="Date Created"
                                value={props.data.created_at}
                                name="created_at"
                                disabled
                                fullWidth={true}
                            ></TextField>
                        </div>

                    </div>
                </div>
            </div>



            <div className="block">
                <div className="container-fluid">
                    <div className="form-row">
                        <div className="form-group col-lg-12">
                            <h3>Lead Qualification</h3>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-lg-12">
                            <h5 style={{ margin: 0 }}>Credit</h5>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-lg-4">

                            <TextField
                                label="Credit Check Score"
                                name="credit_check_score"
                                type="number"
                                value={props.data.credit_check_score}
                                onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                fullWidth={true}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-lg-12">
                            <TextField
                                label="Credit Check Notes"
                                name="credit_check_notes"
                                multiline
                                rows={3}
                                value={props.data.credit_check_notes}
                                onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                fullWidth={true}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-lg-12">
                            <FileBase64
                                multiple={true}
                                onDone={(e) => props.handleUpload(e)}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-lg-12">
                            <h5 style={{ margin: 0 }}>Budget</h5>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-lg-3">
                            <TextField
                                label="Current Spend on Solution"
                                name="current_spend_solution"
                                type="number"
                                value={props.data.current_spend_solution}
                                onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                fullWidth={true}
                            />
                        </div>
                        <div className="form-group col-lg-3">
                            <TextField
                                label="Budget"
                                name="budget"
                                type="number"
                                value={props.data.budget}
                                onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                fullWidth={true}
                            />
                        </div>

                        <div className="form-group col-lg-3">
                            <TextField
                                label="Decision Maker"
                                name="decision_maker"
                                value={props.data.decision_maker}
                                onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                fullWidth={true}
                            />
                        </div>

                        <div className="form-group col-lg-3">
                            <InputLabel>Delivery Date</InputLabel>
                            <TextField
                                name="delivery_date"
                                type="date"
                                value={props.data.delivery_date}
                                onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                fullWidth={true}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-lg-4">
                            <TextField
                                label="Customer Buying Process"
                                name="buying_process"
                                value={props.data.buying_process}
                                onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                fullWidth={true}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}
