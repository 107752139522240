import React, { useEffect, useState, useRef } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import ModuleFunctions from "./ModuleFunctions";
import { PersonnelApi } from "@unity/components";

export default function DealOverview(props) {
  const [loading, setLoading] = useState(false);
  const [versionState, setVersionState] = useState(false);

  const uneditable = !props[props.type] || props.data.retired;

  const history = useHistory();

  useEffect(() => {
    if (props.type === "create") {
      findAgentBus(props.context.auth.agent_id);
    }
    ModuleFunctions.getDealVersion({
      ref: props.data.ref,
      versionChange: setVersionState,
    });
  }, []);

  const handleChange = (newValue) => {
    let a = new Object();
    a[newValue.target.name] = newValue.target.value;
    props.dealChange( {...a , changed: true} );
  };

  const handleOwnerChange = async (item) => {
    props.dealChange({
      changed: true,
      owner_id: item.event.target.value,
      owner_uuid: item.element.props.personObj.uuid,
      owner_name: item.element.props.personObj.name,
    });
    findContactBus(item.event.target.value);
  };

  const findAgentBus = async (agentId) => {
    const person = props.personnel.find((x) => x.id == agentId);
    const filtered = props.structure.find(
      (unit) => unit.value === person.group_uuid
    );
    if (typeof filtered === "undefined") {
      return "Group not found";
    } else {
      let obj = {};
      obj["sbu_id"] = filtered.id;
      obj["sbu_name"] = filtered.name;
      props.dealChange(obj);
    }
  };

  const findContactBus = async (contactId) => {
    const person = props.personnel.find((x) => x.contact_id == contactId);
    const filtered = props.structure.find(
      (unit) => unit.value === person.group_uuid
    );
    if (typeof filtered === "undefined") {
      return "Group not found";
    } else {
      let obj = {};
      obj["sbu_id"] = filtered.id;
      obj["sbu_name"] = filtered.name;
      props.dealChange(obj);
    }
  };

  const handleVersionState = (event) => {
    if (props.isDealConfig) {
      history.push(`/deal-config/${props.type}/${event.target.value}`);
    } else {
      history.push(`/deal-builder/${props.type}/${event.target.value}`);
    }
  };

  return (
    <div className="block">
      <div className="container-fluid">
        <div style={{ width: "100%" }}>
          <div className="form-row">
            <div className="form-group col-lg-12">
              <h4>Deal Overview</h4>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group-sm col-lg-8">
              <TextField
                required
                label="Name"
                name="name"
                type="text"
                onChange={(event) => handleChange(event)}
                defaultValue={props.data.name}
                size="small"
                fullWidth
                InputProps={{ readOnly: uneditable }}
                error={props.errors && props.errors.name}
                helperText={
                  props.errors && props.errors.name ? props.errors.name : ""
                }
              ></TextField>
            </div>

            <div className="form-group-sm col-lg-2">
              <FormControl
                error={(props.errors && props.errors.owner_id) || false}
                fullWidth
              >
                <InputLabel>Sales opportunity</InputLabel>
                <Select
                  label="Deal maker"
                  name="sales_opp_id"
                  value={props.data.sales_opp_id || ""}
                  onChange={(evt) => handleChange(evt)}
                  inputProps={{ readOnly: uneditable }}
                  size="small"
                >
                  {props.salesOpp &&
                    props.salesOpp.map((item, key) => {
                      return (
                        <MenuItem key={key} value={item.id}>
                          {item.sales_order_ref}
                        </MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText>
                  {(props.errors && props.errors.owner_id) || ""}
                </FormHelperText>
              </FormControl>
            </div>

            <div className="form-group-sm col-lg-2">
              <FormControl
                error={(props.errors && props.errors.owner_id) || false}
                fullWidth
              >
                <InputLabel>Version</InputLabel>
                <Select
                  label="Version "
                  name="version"
                  value={props.data.id || ""}
                  onChange={(evt) => handleVersionState(evt)}
                  // inputProps={{ readOnly: uneditable }}
                  size="small"
                >
                  {versionState &&
                    versionState.map((item, key) => {
                      return (
                        <MenuItem key={key} value={item.id}>
                          {item.version}
                        </MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText>
                  {(props.errors && props.errors.owner_id) || ""}
                </FormHelperText>
              </FormControl>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group-sm col-lg-6">
              <FormControl
                error={(props.errors && props.errors.owner_id) || false}
                fullWidth
              >
                <InputLabel>Deal Owner</InputLabel>
                <Select
                  label="Deal maker"
                  name="owner_id"
                  value={props.data.owner_id || ""}
                  onChange={(evt, personObj) => {
                    if (uneditable) return;
                    handleOwnerChange({
                      event: evt,
                      element: personObj,
                    });
                  }}
                  inputProps={{ readOnly: uneditable }}
                  size="small"
                >
                  {props.personnel &&
                    props.personnel.map((item, key) => {
                      return (
                        <MenuItem
                          key={key}
                          value={item.contact_id}
                          personObj={item}
                        >
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText>
                  {(props.errors && props.errors.owner_id) || ""}
                </FormHelperText>
              </FormControl>
            </div>

            <div className="form-group-sm col-lg-6">
              {loading ? (
                <CircularProgress />
              ) : (
                <FormControl
                  error={(props.errors && props.errors.sbu_id) || false}
                  fullWidth
                >
                  <InputLabel>Strategic Business Unit</InputLabel>
                  <Select
                    label="Strategic Business Unit"
                    name="sbu_id"
                    value={props.data.sbu_id || ""}
                    inputProps={{ readOnly: true }}
                    size="small"
                  >
                    {props.bus &&
                      props.bus.map((item, key) => {
                        return (
                          <MenuItem key={key} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <FormHelperText>
                    {(props.errors && props.errors.sbu_id) || ""}
                  </FormHelperText>
                </FormControl>
              )}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group-sm col-lg-12">
              <TextField
                label="Description"
                name="description"
                type="text"
                value={props.data.description || ""}
                multiline
                minRows={2}
                maxRows={5}
                error={(props.errors && props.errors.description) || false}
                helperText={(props.errors && props.errors.description) || ""}
                onChange={(e) =>
                  uneditable ||
                  ModuleFunctions.handleDealChange({
                    name: e.target.name,
                    value: e.target.value,
                    dealChange: props.dealChange,
                  })
                }
                size="small"
                fullWidth
                InputProps={{ readOnly: uneditable }}
              ></TextField>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
