import { Badge } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import React, { useEffect, useState } from 'react';
import { NotificationsApi } from '@unity/components';



export default function NotificationBell({deal_id , context }  ){

    const [notifcations, setNotifications] = useState([]);

    const getNotification = async () => {

        try{
            const res = await NotificationsApi.getTypeNotifications( deal_id , "Deal");

            if( !res.data ) throw "Data not found"

            //setNotifications( res.data )
            filterNotificationList(res.data)

        } catch( e ){
            console.log(`An error has occured. ${e}`)
        }
    }


    const filterNotificationList = (notif) => {
        let arr = []
        let readBool = false
        
        notif.map( row => {
            if(row.customer_id != null || row.customer_id != undefined){
            
            if(row.read_by.length < 1 ){
                setNotifications( notif )
            }
            else{
                row.read_by.map( readByRow => {
                    if( readByRow == context.id ){
                        readBool = true
                        return;
                    }
                })  
            }
            
            if(!readBool){
                arr.push(row)
            } 
            readBool = false // This resets the read bool at start of the loop
        }})
        setNotifications(arr)
      }


    useEffect(
        () => {
            if(deal_id == null || deal_id == undefined) return;

            getNotification()
        } , [ deal_id ]
    )

    return(

        <Badge badgeContent={ notifcations.length } color="primary">
            <NotificationsIcon color="action" />
        </Badge>

    )
}