import React, { useState } from "react";
import FilterTable from "../../../common/FilterTable";
import { TextField } from "@material-ui/core";
export default function ForecastTable(props) {
  const itemData = props.data;

  const startDate = new Date().toISOString().substring(0, 10);

  function getArraySum(a) {
    var total = 0;
    for (var i in a) {
      total += a[i];
    }
    return total;
  }

  const todaysDate = new Date().toISOString().split("T")[0];

  const columns = [
    { name: "sales_opp_overview", label: "Opportunity Name" },
    {
      name: "value",
      label: "Value",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          let testArray = [];
          if (itemData[rowIndex].proposals != undefined) {
            itemData[rowIndex].proposals.map((e) => {
              testArray.push(e.qty * e.unit_cost);
            });

            const sum = getArraySum(testArray);

            itemData[dataIndex]["value"] = sum;
            return sum;
          } else {
            itemData[dataIndex]["value"] = 0;
            return 0;
          }
        },
      },
    },
    { name: "company_name", label: "Customer Name" },
    { name: "stage", label: "Stage" },
    {
      name: "closeDate",
      label: "Close Date",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <TextField
              name="close_date"
              type="date"
              value={props.data[dataIndex].close_date}
              onChange={(e) =>
                props.handleChange(dataIndex, e.target.name, e.target.value)
              }
            />
          );
        },
      },
    },
    {
      name: "chance_won",
      label: "% Chance of Winning",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <TextField
              type="number"
              name="chance_won"
              value={
                itemData[dataIndex].chance_won
                  ? itemData[dataIndex].chance_won
                  : ""
              }
              onChange={(e) =>
                props.handleChange(dataIndex, e.target.name, e.target.value)
              }
            />
          );
        },
      },
    },
    {
      name: "weighted_value",
      label: "Weighted Value",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const weightedValue =
            props.data[dataIndex].value *
            (props.data[dataIndex].chance_won / 100);

          itemData[dataIndex]["weighted_value"] = weightedValue;
          return weightedValue.toFixed(2);
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    responsive: "stacked",
    rowsPerPage: 100,
    print: true,
    expandableRowsOnClick: false,
    expandableRows: true,
    onRowExpansionChange: (
      currentRowsExpanded,
      allRowsExpanded,
      rowsExpanded
    ) => {
      props.setExpandedRows(allRowsExpanded);
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const columns = [
        { name: "service_description", label: "Item Name" },
        {
          name: "value",
          label: "Value",
          options: {
            customBodyRenderLite: (dataIndex) => {
              const value =
                itemData[rowMeta.rowIndex].proposals[dataIndex].qty *
                itemData[rowMeta.rowIndex].proposals[dataIndex].unit_cost;

              itemData[rowMeta.rowIndex].proposals[dataIndex]["value"] =
                value.toFixed(2);
              return value;
            },
          },
        },
        {
          name: "close_date",
          label: "Est Close Date",
          options: {
            customBodyRenderLite: (dataIndex) => {
              return (
                <TextField
                  name="close_date"
                  type="date"
                  defaultValue={todaysDate}
                  value={
                    itemData[rowMeta.rowIndex].proposals[dataIndex].close_date
                  }
                  onChange={(e) =>
                    props.handleProposalsChange(
                      rowMeta.rowIndex,
                      e.target.name,
                      e.target.value,
                      dataIndex
                    )
                  }
                />
              );
            },
          },
        },
        {
          name: "chance_won",
          label: "% Chance of winning",
          options: {
            customBodyRenderLite: (dataIndex) => {
              return (
                <TextField
                  type="number"
                  name="chance_won"
                  value={
                    itemData[rowMeta.rowIndex].proposals[dataIndex].chance_won
                      ? itemData[rowMeta.rowIndex].proposals[dataIndex]
                          .chance_won
                      : ""
                  }
                  onChange={(e) =>
                    props.handleProposalsChange(
                      rowMeta.rowIndex,
                      e.target.name,
                      e.target.value,
                      dataIndex
                    )
                  }
                />
              );
            },
          },
        },
        {
          name: "weighted_value",
          label: "Weighted Value",
          options: {
            customBodyRenderLite: (dataIndex) => {
              const weightedValue =
                itemData[rowMeta.rowIndex].proposals[dataIndex].value *
                (itemData[rowMeta.rowIndex].proposals[dataIndex].chance_won /
                  100);

              itemData[rowMeta.rowIndex].proposals[dataIndex][
                "weighted_value"
              ] = weightedValue;
              return weightedValue;
            },
          },
        },
      ];

      const options = {
        rowsPerPage: 100,
      };

      return (
        <>
          <td colSpan={8}>
            <FilterTable
              columns={columns}
              data={itemData[rowMeta.rowIndex].proposals}
              options={options}
            />
          </td>
        </>
      );
    },
  };

  return (
    <>
      <FilterTable columns={columns} data={props.data} options={options} />
    </>
  );
}
