import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ModuleButton from "../../common/ModuleButton";
import { TextField } from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag";
import Checkbox from "@mui/material/Checkbox";

const ProductLines = (props) => {
  //const uneditable = props.uneditable && !props.approved

  return (
    <div className="block">
      <div className="container-fluid">
        <div style={{ width: "100%" }}>
          <h4>Product Lines</h4>
          <br></br>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {props.isDealApproved ? null : (
                    <TableCell padding="checkbox">
                      <Checkbox color="primary" />
                    </TableCell>
                  )}
                  <TableCell>Name</TableCell>
                  <TableCell align="left">period</TableCell>
                  <TableCell align="left">Cost</TableCell>
                  <TableCell align="left">Quantity</TableCell>
                  <TableCell align="left">Price</TableCell>
                  <TableCell align="right">Type</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.productLines
                  ? props.productLines.map((row, index) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {props.isDealApproved ? null : (
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              onClick={() => props.handleCheckBox(row)}
                            />
                          </TableCell>
                        )}

                        <TableCell component="th" scope="row">
                          {row.description}
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {props.getPeriodicData(row.periodic_code_id)}
                        </TableCell>

                        <TableCell align={row.dynamic ? "left" : "right"}>
                        {row.dynamic ?
                                                (props.uneditable ?
                                                    <p>Please click the view button to see the price</p>
                                                    :
                                                <p>Please click edit to add a price</p>)
                                                :
                          <TextField
                            label=""
                            name="unit_price"
                            type="number"
                            onChange={(e) => props.calculateProductLineMarkup(index, e)}
                            value={row.unit_price || ""}
                            size="small"
                            fullWidth
                            error={
                              props.errors &&
                              props.errors[`lines.${index}.unit_price`]
                            }
                            helperText={
                              props.errors &&
                              props.errors[`lines.${index}.unit_price`]
                                ? props.errors[`lines.${index}.unit_price`]
                                : ""
                            }
                            InputProps={{ readOnly: props.uneditable }}
                          ></TextField> }
                        </TableCell>

                        <TableCell align="right">
                          <TextField
                            label=""
                            name="quantity"
                            type="number"
                            onChange={(e) => props.calculateProductLineMarkup(index, e)}
                            value={row.quantity || ""}
                            size="small"
                            fullWidth
                            error={
                              props.errors &&
                              props.errors[`lines.${index}.quantity`]
                            }
                            helperText={
                              props.errors &&
                              props.errors[`lines.${index}.quantity`]
                                ? props.errors[`lines.${index}.quantity`]
                                : ""
                            }
                            InputProps={{ readOnly: props.uneditable }}
                          ></TextField>
                        </TableCell>

                        {row.dynamic ? (
                          <TableCell align="left">TBC</TableCell>
                        ) : (
                          <TableCell align="right">
                            <TextField
                              label=""
                              name="price"
                              type="number"
                              onChange={(e) => props.updateLine(index, e)}
                              value={row.price || ""}
                              size="small"
                              fullWidth
                              error={
                                props.errors &&
                                props.errors[`lines.${index}.price`]
                              }
                              helperText={
                                props.errors &&
                                props.errors[`lines.${index}.price`]
                                  ? props.errors[`lines.${index}.price`]
                                  : ""
                              }
                              InputProps={{ readOnly: true }}
                            ></TextField>
                          </TableCell>
                        )}

                        <TableCell align="right">
                          {row.dynamic ? (
                            <FlagIcon sx={{ color: "red" }} />
                          ) : null}
                        </TableCell>

                        <TableCell align="right">

                        {row.dynamic ?
                                        
                                        (props.uneditable ? 
                                            <ModuleButton
                                        btnIcon="visibility"
                                        style={{ backgroundColor: "#1976d2" }}
                                        onClick={() => props.handleFullBox(index)}
                                        tip="View"
                                    />
                                    :
                                        <ModuleButton
                                        btnIcon="edit"
                                        style={{ backgroundColor: "orange" }}
                                        onClick={() => props.handleFullBox(index)}
                                        tip="Edit"
                                    />)
                                        :
                                            <ModuleButton
                                            btnIcon="visibility"
                                            style={{ backgroundColor: "#1976d2" }}
                                            onClick={() => props.handleFullBox(index)}
                                            tip="View"
                                        />}


                        </TableCell>
                      </TableRow>
                    ))
                  : ""}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default ProductLines;
