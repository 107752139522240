import React, { useState, useEffect } from "react";

import {
  AppHeaderComponent,
  SalesOpApi,
  LeadsApi,
  ClientsApi,
  CodesApi,
  PersonnelApi,
  BusinessApi
} from "@unity/components";
import ApiLoader from "../common/ApiLoader";
import { useHistory } from "react-router-dom";
import SalesOpForm from "../modules/SalesOp/SalesOpForm";
import Loading from "../common/Loading";

export default function SalesOpCreate({ route, context }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState({ status: false });
  let history = useHistory();

  let comingFromleads;

  const [leadsData, setLeadsData] = useState({});
  const [leadsLoading, setLeadLoading] = useState(false);
  const [companies, setCompanies] = useState({});
  const [companyLoaded, setCompanyLoaded] = useState(false);

  const [businesses, setBusinesses] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [users, setUsers] = useState(false);

  if (route.location.state) {
    comingFromleads = route.location.state.comingFromleads;
    data["company_name"] = leadsData["company_name"];
    data["contact_phone"] = leadsData["contact_phone"];
    data["contact_email"] = leadsData["contact_email"];
    data["customer_id"] = leadsData["customer_id"];
  }

  const handleSave = async () => {
    setLoading({ status: true, data: "Loading please wait..." });

    const x = await SalesOpApi.saveSalesOpp(data);

    setLoading({ status: true, data: "Save Success please wait..." });
    setTimeout(() => {
      history.push("/sales-op/index");
    }, 4000);
  };

  const handleChange = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const handleUpload = (e) => {
    const newData = { ...data };
    newData["credit_documents"] = e;
    setData(newData);
  };

  const handleCompanySelect = (e) => {
    const newData = { ...data };
    newData["customer_id"] = e.id;
    newData["company_name"] = e.name;
    newData["contact_phone"] = e.phone;
    newData["sic_code"] = e.sic_id;
    setData(newData);
  };

  const handleBusinessChange = async (val) => {
    const newData = { ...data };
    newData["business_unit"] = val;
    setData(newData);
    await getProductCategories(val);
  };

  const getLeadsSingle = async (id) => {
    setLeadLoading(true);
    if (id) {
      const res = await LeadsApi.showLeads(id);
      if (!res.status || res.status !== 200) {
        res.data.client_address = res.data.client_first_line;
        setLeadsData(res.data);
      }
      setLeadLoading(false);
    }
  };

  const getBusinessUnits = async () => {
    const res = await CodesApi.getBusinessUnits();
    setBusinesses(res.data);
  };

  const getProductCategories = async (id) => {
    const res = await CodesApi.subOptionsIndexForOption(id);
    setProductCategories(res.data);
  };

  const getClients = async (filter = null) => {
    try {
      const res = await BusinessApi.businessIndexFiltered({
        rules: [{field: "customer_name", operator: "LIKE", value: filter !== null ? `%${filter}%` : ""}]
      });
      if(!res.ok) throw "Call Failed";
      setCompanies(res.data.data);
      setCompanyLoaded(true);
    } catch (error) {
      alert(`Failed to fetch businesses - ${error}`);
    }
  };

  const getUsers = async () => {
    const personnel = await PersonnelApi.getPersonnel();
    setUsers(personnel.data);
  };
  useEffect(() => {
    if (route.location.state) {
      getLeadsSingle(route.location.state.id);
    }
    getClients();
    getBusinessUnits();
    getUsers();
  }, []);

  if (data && !leadsLoading) {
    return (
      <>
        <AppHeaderComponent
          saveBtn={handleSave}
          context={context}
          theme={context.theme}
          name="sales-op"
          subpage="create"
        />
        <ApiLoader status={loading.status} data={loading.data} />
        <div style={{ paddingTop: 120, paddingBottom: 40 }}>
          <SalesOpForm
            data={data}
            companies={companies}
            companyLoaded={companyLoaded}
            comingFromleads={comingFromleads}
            business_units={businesses}
            productCategories={productCategories}
            users={users}
            setData={setData}
            handleCompanySelect={handleCompanySelect}
            handleChange={handleChange}
            handleUpload={handleUpload}
            handleBusinessChange={handleBusinessChange}
            getClients={getClients}
            type="create"
          />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
