import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ModuleButton from "../../common/ModuleButton";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  DealBuilderApi,
  AppHeaderComponent,
  LoadingComponent,
  ApiLoaderComponent,
} from "@unity/components";
// import ProductStructure from "./DisplayTree";

const PersonSelectionDialogBox = (props) => {
  const [modelInfo, setModelInfo] = useState(false); // Used to get the level type from the displayTree
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState({ status: false });

  const getModelInfo = (data) => {
    setModelInfo(data);
  };

  const handleSave = async () => {
    setLoading({ status: true, data: "Assigning person, Please Wait...." });

    const obj = { deal_id: parseInt(props.deal_id), ...props.teamMember };
    const res = await DealBuilderApi.saveTeamMember(obj);

    if (res.success) {
      setTimeout(() => {
        // history.push(`/deal-builder/edit/${props.deal_id}`);
        setLoading({ status: false });
      }, 3000);
      props.triggerChange();
      props.closeDialog();
    } else {
      if (res.errors) {
        setErrors(res.errors);
        setLoading({
          status: true,
          data: "Validation Errors!, Please Wait....",
        });
        setTimeout(() => {
          setLoading({ status: false });
        }, 3000);
      } else {
        setLoading({ status: true, data: res.message });
        setTimeout(() => {
          //history.push("/deal-builder/index");
          setLoading({ status: false });
        }, 3000);
      }
    }
  };

  const handleUpdateTeamMember = async () => {
    setLoading({
      status: true,
      data: "Updating assigned person, Please Wait....",
    });

    const res = await DealBuilderApi.updateTeamMember(
      props.teamMember.id,
      props.teamMember
    );

    if (res.success) {
      props.teamMemberChange({ changed: false });
      setLoading({ status: false });
      props.triggerChange();
      props.closeDialog();
    } else {
      if (res.errors) {
        setErrors(res.errors);
        setLoading({
          status: true,
          data: "Validation Errors!, Please Wait....",
        });
        setTimeout(() => {
          setLoading({ status: false });
        }, 3000);
      } else {
        setLoading({ status: true, data: res.message });
        setTimeout(() => {
          //history.push("/deal-builder/index");
          setLoading({ status: false });
        }, 3000);
      }
    }
  };

  return (
    <>
      <Dialog
        open={props.openDialog}
        onClose={props.closeDialog}
        fullWidth={true}
        maxWidth={"xl"}
      >
        <DialogTitle>
          <h4>Assign a person</h4>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormControl
              error={(errors && errors.responsible_id) || false}
              fullWidth
            >
              <InputLabel>Assign Person</InputLabel>
              <Select
                label="Assign Person"
                name="responsible_id"
                value={props.teamMember.responsible_id || ""}
                style={{ marginTop: "5px" }}
                onChange={(evt) => props.editTeamMember(evt)}
                size="small"
              >
                {props.personnel &&
                  props.personnel.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.contact_id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </Select>
              <FormHelperText>
                {(errors && errors.responsible_id) || ""}
              </FormHelperText>
            </FormControl>

            <TextField
              name="instructions"
              label="instructions"
              onChange={(evt) => props.editTeamMember(evt)}
              style={{ width: "100%", marginTop: "10px" }}
              value={props.teamMember.instructions || ""}
            />

            {props.teamMember &&
            props.teamMember.responsible_id &&
            props.edit &&
            props.editTeamMemberState ? (
              <div
                style={{
                  width: "100%",
                  marginTop: "10px",
                  border: "solid 1px darkBlue",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                  borderRadius: "5px",
                }}
              >
                <span style={{ marginLeft: "15px" }}>
                  Currently Assigned to <strong>{props.levelName}</strong>
                </span>
              </div>
            ) : null}

            {/* <ProductStructure modelInfo={getModelInfo} context={props.context} dialogChange={props.teamMemberChange} /> */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ModuleButton
            btnIcon="clear"
            text="Cancel"
            style={{
              background: "red",
              color: "white",
              marginRight: 10,
            }}
            tip="Save"
            onClick={() => props.closeDialog()}
          />

          {props.teamMember &&
          props.teamMember.responsible_id &&
          props.edit &&
          props.editTeamMemberState ? (
            <ModuleButton
              btnIcon="check"
              text="Update"
              style={{
                background: "blue",
                color: "white",
                marginRight: 10,
              }}
              tip="Save"
              onClick={() => handleUpdateTeamMember()}
            />
          ) : null}

          {!props.editTeamMemberState ? (
            props.teamMember && props.teamMember.responsible_id ? (
              <ModuleButton
                btnIcon="check"
                text="Save"
                style={{
                  background: "blue",
                  color: "white",
                  marginRight: 10,
                }}
                tip="Save"
                onClick={() => handleSave()}
              />
            ) : (
              <ModuleButton
                btnIcon="clear"
                text="Save"
                style={{
                  background: "lightGray",
                  color: "black",
                  marginRight: 10,
                }}
                tip="Select a person"
              />
            )
          ) : null}
        </DialogActions>
      </Dialog>

      <ApiLoaderComponent status={loading.status} data={loading.data} />
    </>
  );
};

export default PersonSelectionDialogBox;
