import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ModuleFunctions from "./ModuleFunctions";
import DataTable from "../../common/DataTable";
import { CanDelete } from "../../services/Permissions";
import ModuleButton from "../../common/ModuleButton";
import { DealBuilderApi, ApiLoaderComponent } from "@unity/components";
import CheckIcon from "@mui/icons-material/Check";
import WarningIcon from "@mui/icons-material/Warning";
import { IconButton, Tooltip } from "@mui/material";

const ProductDisplayTable = (props) => {
  const [fetchTrigger, setFetchTrigger] = useState(0); // This is used to determine if page needs to be re-rendered. The value doesn't matter, just the change!
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0); // The total number of rows available from the search!
  const [pagination, setPagination] = useState(false); // Flag to switch between unpaginated and paginated data!
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState({ status: false });

  // The following functions are both required for the correct
  // async use of deleting multiple rows!
  // -----------------------------------------------------------------------------------
  const handleDelete = async (id) => {
    await DealBuilderApi.deleteLiveProduct(id);
  };

  const handleDeleteArray = async (array) => {
    setLoading({ status: true, data: "Deleting product, please wait..." });
    const unresolvedPromises = array.map((id) => handleDelete(id));

    await Promise.all(unresolvedPromises)
      .then((success) => {
        setLoading({ status: true, data: "Succssfully deleted product!" });
        props.triggerChange();
      })
      .catch((error) => {
        setLoading({
          status: true,
          data: `Unable to delete product with id: ${error}`,
        });
      });

    setFetchTrigger(fetchTrigger + 1);

    setTimeout(() => {
      setLoading({ status: false });
    }, 3000);
  };
  // -----------------------------------------------------------------------------------

  let history = useHistory();

  const handlePageChange = (page) => {
    setPage(page);

    if (pagination) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleSizeChange = (size) => {
    setPageSize(size);
    if (pagination) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const triggerChange = () => {
    setFetchTrigger(fetchTrigger + 1);
  };

  const convertDate = (inDate) => {
    if(inDate != null){
      let newDate = inDate.toLocaleString()
      let splicedDate = newDate.slice(0, 10) 
      
      return splicedDate.split("-").reverse().join('/')

    }
    else{
      return ""
    }
  }

  const columns = [
    { field: "product_ref", headerName: "Ref", flex: 0.3 },
    { field: "name", headerName: "Deal Name", flex: 1 },
    {
      field: "valid_from",
      headerName: "Valid From",
      minWidth: 200,
      renderCell: (cellValues) => {
        if (cellValues.row.valid_from) {
          return convertDate(cellValues.row.valid_from);
        } else {
          return <span style={{ color: "red" }}>Valid from date not set</span>;
        }
      },
    },
    {
      field: "valid_to",
      headerName: "Valid To",
      flex: 0.5,
      renderCell: (cellValues) => {
        if (cellValues.row.valid_to) {
          return convertDate(cellValues.row.valid_to);
        } else {
          return <span style={{ color: "red" }}>Valid to date not set</span>;
        }
      },
    },
    {
      field: "responsible_id",
      headerName: "Product owner",
      flex: 0.5,
      renderCell: (cellValues) => {
        if (cellValues.row.responsible_id && props.personnel) {
          const un = props.personnel.find(
            (x) => x.contact_id == cellValues.row.responsible_id
          );
          return un.name;
        } else {
          return null;
        }
      },
    },
    {
      field: "approved",
      headerName: "Status",
      flex: 0.3,
      renderCell: (cellValues) => {
        if (cellValues.row.approved) {
          return (
            <Tooltip title="Product approved">
              <IconButton>
                <CheckIcon style={{ color: "green" }} />
              </IconButton>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title="Product not approved">
              <IconButton>
                <WarningIcon style={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          );
        }
      },
    },
    {
      field: "",
      headerName: "Action",
      minWidth: 320,
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.row.approved && !props.isDealRetired ? (
              <div>
                <ModuleButton
                  btnIcon="edit"
                  style={{ backgroundColor: "orange", color: "white" }}
                  onClick={(event) =>
                    props.handleLinkClick(event, cellValues.row.id, "product")
                  }
                  tip="View"
                />

                {props.isDealOwner ? 
                <ModuleButton
                  btnIcon="email"
                  style={{ backgroundColor: "#189AB4", marginLeft: "10px" }}
                  //onClick={() => productChange(cellValues.row)}
                  onClick={() =>
                    props.openRequestChangeDialogBox(cellValues.row)
                  }
                  tip="Request change"
                />
                : null}

                {props.ownerPermission ? (
                  cellValues.row.selected ? (
                    <ModuleButton
                      tip="De-select product from deal"
                      btnIcon="playlist_remove"
                      style={{ backgroundColor: "red", marginLeft: "10px" }}
                      //onClick={() => productChange(cellValues.row)}
                      onClick={() =>
                        props.handleProductSelectToggle(cellValues.row)
                      }
                    />
                  ) : (
                    <ModuleButton
                      tip="Select product for deal"
                      btnIcon="playlist_add_check"
                      style={{ backgroundColor: "#50C878", marginLeft: "10px" }}
                      //onClick={() => productChange(cellValues.row)}
                      onClick={() =>
                        props.handleProductSelectToggle(cellValues.row)
                      }
                    />
                  )
                ) : null}

                {cellValues.row.selected ? (
                  cellValues.row.displayed ? (
                    <ModuleButton
                      tip="Hide product from customer"
                      btnIcon="desktop_access_disabled"
                      style={{ backgroundColor: "red", marginLeft: "10px" }}
                      //onClick={() => productChange(cellValues.row)}
                      onClick={() =>
                        props.handleProductDisplayToggle(cellValues.row)
                      }
                    />
                  ) : (
                    <ModuleButton
                      tip="Display product to customer"
                      btnIcon="tv"
                      style={{ backgroundColor: "#50C878", marginLeft: "10px" }}
                      //onClick={() => productChange(cellValues.row)}
                      onClick={() =>
                        props.handleProductDisplayToggle(cellValues.row)
                      }
                    />
                  )
                ) : (
                  <ModuleButton
                    tip="Unable to display. Please select a product"
                    btnIcon="tv"
                    style={{ backgroundColor: "gray", marginLeft: "10px" }}
                    //onClick={() => productChange(cellValues.row)}
                    //onClick={() => props.handleProductDisplayToggle(cellValues.row)}
                  />
                )}
              </div>
            ) : (
              <div>
                <ModuleButton
                  btnIcon="visibility"
                  style={{ backgroundColor: "blue", color: "white" }}
                  onClick={(event) =>
                    props.handleLinkClick(event, cellValues.row.id, "product")
                  }
                  tip="View"
                />
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const options = {
    page: page,
    handlePageChange: handlePageChange,
    pageSize: pageSize,
    handleSizeChange: handleSizeChange,
    rowsPerPageOptions: [10, 25, 100],
    rowCount: rowCount,
    autoHeight: true,
    height: 300,
  };

  return (
    <div className="block">
      <div className="container-fluid">
        <div style={{ width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h4>Products</h4>
            {props.ownerPermission ? (
              <ModuleButton
                btnIcon="add"
                text="Add Products"
                style={{ backgroundColor: "blue", color: "white" }}
                onClick={() => props.handleProductSelectionDialogBox()}
              />
            ) : null}
          </div>

          <div style={{ marginTop: "10px" }}></div>

          <DataTable
            columns={columns}
            data={props.data}
            pagination={pagination}
            options={options}
            allowDelete={props.ownerPermission ? CanDelete("sales-op") : false}
            handleDeleteArray={handleDeleteArray}
            searchText={searchText}
            setSearchText={setSearchText}
            fetchTrigger={fetchTrigger}
            setFetchTrigger={setFetchTrigger}
            checkboxSelection
          />

          <ApiLoaderComponent status={loading.status} data={loading.data} />
        </div>
      </div>
    </div>
  );
};

export default ProductDisplayTable;
