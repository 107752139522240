import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";

import DataTable from "../../../common/DataTable";
import Widget from "../Widget";

export default function ReportTable({ data, type = true }) {

  const [ total, setTotal ] = useState(0);

  const calcTotal = () => {
    let total = 0;

    if(data) {
      data.map((opp) => {
        total += opp.opp_value;
      })

      setTotal(total);
    }
  }

  const columns = [
    { field: "sales_opp_overview", headerName: "Opportunity Name", flex: 1 },
    { field: "company_name", headerName: "Customer", flex: 1 },
    { field: "agent_name", headerName: type ? "Won By" : "Lost By", flex: 1 },
    { field: "opp_value", headerName: "Value", flex: 1 },
  ];

  useEffect(() => {
    calcTotal()
  }, [data])

  return (
    <>
      <DataTable columns={columns} rows={data} height={300} />
      <Grid container spacing={2} justifyContent="center" style={{ margin: "10px" }}>
        <Grid item xs={6} style={{ textAlign: "center" }}>
          <Widget
            count={`£${total}`}
            title={type ? "Total Amount Won" : "Total Amount Lost"}
            bg={type ? "rgb(132, 229, 137)" : "rgb(255, 133, 137)" }
          />
        </Grid>
      </Grid>
    </>
  );
}
