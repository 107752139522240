import config from "../config";
import React, { useEffect, useState } from "react";
import {
  AppHeaderComponent,
  SalesOpApi,
  DealBuilderApi,
  ApiLoaderComponent,
} from "@unity/components";
import Loading from "../common/Loading";
import { useHistory } from "react-router-dom";
import ApiLoader from "../common/ApiLoader";
import SalesOpInterface from "../modules/SalesOp/SalesOpInterface";
import PersonDisplayTable from "../modules/dealModules/PersonDisplayTable";
import ModuleFunctions from "../modules/dealModules/ModuleFunctions";
import {
  CanCreate,
  CanRead,
  CanReadAll,
  CanUpdate,
  CanDelete,
} from "../services/Permissions";
import ProductDisplayTable from "../modules/dealModules/ProductDisplayTable";
import DealOverview from "../modules/dealModules/DealOverview";
import PersonSelectionDialogBox from "../modules/dealModules/PersonSelectionDialogBox";
import ProductSelectionDialogBox from "../modules/dealModules/ProductSelectionDialogBox";
import RequestChangeDialogBox from "../modules/dealModules/RequestChangeDialogBox";
import { TextField } from "@material-ui/core";

export default function SalesOpDeal({ route, context }) {
  const [loading, setLoading] = useState({ status: false });
  const [fetchTrigger, setFetchTrigger] = useState(0); // This is used to determine if page needs to be re-rendered. The value doesn't matter, just the change!
  const [data, setData] = useState(false);
  const [errors, setErrors] = useState(false);
  const [personnel, setPersonnel] = useState(false);
  const [structure, setStructure] = useState(false);
  const [periods, setPeriods] = useState(false);
  const [charges, setCharges] = useState(false);
  const [methods, setMethods] = useState(false);
  const [vats, setVats] = useState(false);
  const [bus, setBus] = useState(false);
  const [salesOpp, setSalesOpp] = useState(false);

  const [teamMember, setTeamMember] = useState(false); //the ID to call assigned person Api when editing an existing user
  const [editTeamMemberState, setEditTeamMemberState] = useState(false); //Bool checker for the person dialog - checks if the user has already been assigned
  const [levelName, setLevelName] = useState(false); //Displays the current level name to the user when in the person dialog box

  const [requestChangeDialogBox , setRequestChangeDialogBox] = useState(false);
  const [requestChangeData, setRequestChangeData] = useState(false) //Product data passed to the change request dialog box

  const [productSelectionDialogBox, setProductSelectionDialogBox] = useState(false);
  const [personAssignmentDialogBox, setPersonAssignmentDialogBox] = useState(false);

  const [instructionsDialogBox, setInstructionsDialogBox] = useState(false);
  const [instructions, setIntructions] = useState(false);

  const [nonDealOwnerProducts, setNonDealOwnerProducts] = useState(false)

  const [salesOpData, setSalesOpData] = useState(false);

  const date = new Date();

  const forwardURL = () => {
    const urlString = config.appURL;
    const split = urlString.split('.');
    split[1] = "deal-builder";
    const newUrl = split.join('.');

    window.open( 'https://'+newUrl , '_blank' );
  }

  const ownerPermission =
    (data.owner_id == context.auth.contact) || data.retired;

  const isDealOwner = data.owner_id == context.auth.contact

  const isDealRetired =
    data.retired || (data.approved && new Date(data.valid_to) < date);

  const create = CanCreate("sales-op");
  const read = CanRead("sales-op");
  const edit = CanUpdate("sales-op");
  //const admin = CanModuleAdmin("sales-op");

  let history = useHistory();

  const openRequestChangeDialogBox = (obj , data) => {
    setRequestChangeData(obj);
    setRequestChangeDialogBox(true);
  }

  const closeRequestChangeDialogBox = () => {
    setRequestChangeData(false);
    setRequestChangeDialogBox(false);
  }

  const getSalesOpSingle = async () => {
    const res = await SalesOpApi.getSalesOppSingle(route.match.params.id);
    setSalesOpData(res.data);
  };

  const handleLinkClick = (event, params, path) => {
    event.preventDefault();
    history.push({
      pathname: `/sales-op/${path}/${params}`,
      state: { ...params },
    });
  };

  const dealChange = (data) => {
    setData((prevState) => ({
      ...prevState,
      ...data,
    }));
    
    if(typeof data.live_products !== 'undefined') {
      HandleProductFilter(data.live_products);
    }
  };

  const HandleProductFilter = (products) => {
    const newArray = [];
    const filteredProd = products.map(
      (e) => {
      if(context.auth.contact == e.responsible_id){
        newArray.push(e);
      }
      }
    )
    setNonDealOwnerProducts(newArray);
  }

  const handleProductSelectToggle = async (obj) => {
    setLoading({ status: true, data: "Selecting Product, Please Wait...." });
    const res = await DealBuilderApi.liveProductSelectToggle(obj.id, obj);

    if (res.errors) {
      setErrors(res.errors);
      setLoading({
        status: true,
        data: "Validation Errors!, Please Wait....",
      });
      setTimeout(() => {
        triggerChange();
        setLoading({ status: false });
      }, 3000);
    } else {
      setLoading({ status: true, data: res.message });
      setTimeout(() => {
        triggerChange();
        setLoading({ status: false });
      }, 3000);
    }
  };

  const handleProductDisplayToggle = async (obj) => {
    setLoading({ status: true, data: "Updating product, Please Wait...." });
    const res = await DealBuilderApi.liveProductDisplayToggle(obj.id, {
      ...obj,
      contact_id: salesOpData.site_contact_id
    });

    if (res.errors) {
      setErrors(res.errors);
      setLoading({
        status: true,
        data: "Validation Errors!, Please Wait....",
      });
      setTimeout(() => {
        triggerChange();
        setLoading({ status: false });
      }, 3000);
    } else {
      setLoading({ status: true, data: res.message });
      setTimeout(() => {
        triggerChange();
        setLoading({ status: false });
      }, 3000);
    }
  };

  const handleSave = async () => {
    setLoading({ status: true, data: "Loading please wait..." });

    setLoading({ status: true, data: "Save Success please wait..." });
    setTimeout(() => {
      history.push("/leads/index");
    }, 4000);
  };

  const handleNavigation = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const handleUpload = (e) => {
    const newData = { ...data };
    newData["credit_documents"] = e;
    setData(newData);
  };

  const teamMemberChange = (data) => {
    setTeamMember((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const editTeamMember = (newValue) => {
    const newData = { ...teamMember };
    newData[newValue.target.name] = newValue.target.value;
    setTeamMember(newData);
  };

  const triggerChange = () => {
    setFetchTrigger(fetchTrigger + 1);
  };

  const personnelChange = (data) => {
    setPersonnel(data);
  };

  const structureChange = (data) => {
    setStructure(data);
  };

  const periodsChange = (data) => {
    setPeriods(data);
  };

  const vatsChange = (data) => {
    setVats(data);
  };

  const chargesChange = (data) => {
    setCharges(data);
  };

  const methodsChange = (data) => {
    setMethods(data);
  };

  const busChange = (data) => {
    setBus(data);
  };

  const salesOppChange = (data) => {
    setSalesOpp(data);
  };

  const openInstructionsDialogBox = (data) => {
    setIntructions(data.instructions);
    setInstructionsDialogBox(true);
  };

  const handleProductSelectionDialogBox = () => {
    productSelectionDialogBox
      ? setProductSelectionDialogBox(false)
      : setProductSelectionDialogBox(true);
  };

  const openPersonSelectionDialogBox = (data) => {
    setEditTeamMemberState(false);
    setTeamMember(data);
    setPersonAssignmentDialogBox(true);
  };

  const closePersonSelectionDialogBox = () => {
    setLevelName(false);
    setEditTeamMemberState(false);
    setPersonAssignmentDialogBox(false);
    setTeamMember(false);
  };

  const editPersonSelectionDialogBox = (data) => {
    // setLevelName(data.level_name);
    // setEditTeamMemberState(true);
    // setTeamMember(data);
    // setPersonAssignmentDialogBox(true);
  };


  const handleDealSave = async () => {
    setLoading({ status: true, data: "Updating your Deal, Please Wait...." });

    const res = await DealBuilderApi.updateDeal(route.match.params.id, data);

    if (res.errors) {
      setErrors(res.errors);
      setLoading({ status: true, data: "Validation Errors!, Please Wait...." });
      setTimeout(() => {
        setLoading({ status: false });
      }, 3000);
    } else {
      setLoading({ status: true, data: res.message });
      setTimeout(() => {
        dealChange({ changed: false });
        setLoading({ status: false });
      }, 3000);
    }
  };

  useEffect(() => {
    getSalesOpSingle();
    ModuleFunctions.getPersonnel({ personnelChange: personnelChange });
    ModuleFunctions.getPeriodicCodes({ periodsChange: periodsChange });
    ModuleFunctions.getChargeCodes({ chargesChange: chargesChange });
    ModuleFunctions.getPaymentMethods({ methodsChange: methodsChange });
    ModuleFunctions.getVatRates({ vatsChange: vatsChange });
    ModuleFunctions.getBusinesses({ change: busChange });
    ModuleFunctions.getSalesOpps({ salesOppChange: salesOppChange });
    ModuleFunctions.getDeal({
      id: route.match.params.id,
      dealChange: dealChange,
    });
    ModuleFunctions.getStructure({ structureChange: structureChange });
  }, [ fetchTrigger ]);

  if (data && personnel && bus && nonDealOwnerProducts) {
    return (
      <>
        <AppHeaderComponent
          saveBtn={data.changed ? handleDealSave : null}
          context={context}
          theme={context.theme}
          name="sales-op"
          subpage="Deal"
        />
        <ApiLoader status={loading.status} data={loading.data} />

        <DealOverview
          context={context}
          type="edit"
          create={create}
          read={read}
          edit={edit}
          data={data}
          errors={errors}
          personnel={personnel}
          structure={structure}
          periods={periods}
          charges={charges}
          methods={methods}
          vats={vats}
          bus={bus}
          salesOpp={salesOpp}
          dealChange={dealChange}
          ownerPermission={ownerPermission}
        />

        <div className="block">
          <div className="container-fluid">
            <div style={{ width: '100%' }}>
              <div style={{ display: "flex", width: "50%", justifyContent: "space-between", alignItems: "center", marginTop: "15px", marginBottom: "15px" }} >
                <div>
                  <TextField
                    label="Valid From"
                    name="valid_from"
                    value={data.valid_from ? new Date(data.valid_from).toLocaleDateString() : ""}
                    size="small"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  ></TextField>
                </div>
                <div>
                  <TextField
                    label="Valid To"
                    name="valid_to"
                    value={data.valid_to ? new Date(data.valid_to).toLocaleDateString(): ""}
                    size="small"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  ></TextField>
                </div>
              </div>
            </div>
          </div>
        </div>

        {ownerPermission ? (
          <PersonDisplayTable
            data={data.team_members}
            deal_id={route.match.params.id}
            openDialog={openPersonSelectionDialogBox}
            personnel={personnel}
            edit={edit}
            triggerChange={triggerChange}
            editPersonSelectionDialogBox={editPersonSelectionDialogBox}
            ownerPermission={ownerPermission}
            isDealRetired={isDealRetired}
            openInstructionsDialogBox={openInstructionsDialogBox}
            forwardURL={forwardURL}
          />
        ) : null}

        <ProductDisplayTable
          deal_id={route.match.params.id}
          data={ isDealOwner ? data.live_products : nonDealOwnerProducts}
          handleLinkClick={handleLinkClick}
          handleProductSelectionDialogBox={handleProductSelectionDialogBox}
          handleProductDisplayToggle={handleProductDisplayToggle}
          bus={bus}
          triggerChange={triggerChange}
          context={context}
          openRequestChangeDialogBox={openRequestChangeDialogBox}
          handleProductSelectToggle={handleProductSelectToggle}
          personnel={personnel}
          ownerPermission={ownerPermission}
          isDealRetired={isDealRetired}
          isDealOwner={isDealOwner}
          salesData={salesOpData}
        />

        <RequestChangeDialogBox 
          open={requestChangeDialogBox}
          close={closeRequestChangeDialogBox}
          data={requestChangeData}
          dealData={data}
          triggerChange={triggerChange}
          productRequest={true}
        />

        <ProductSelectionDialogBox
          open={productSelectionDialogBox}
          close={handleProductSelectionDialogBox}
          data={data.live_products}
          deal_id={route.match.params.id}
          dealChange={dealChange}
          triggerChange={triggerChange}
        />

        <PersonSelectionDialogBox
          deal_id={route.match.params.id}
          personnel={personnel}
          context={context}
          openDialog={personAssignmentDialogBox}
          closeDialog={closePersonSelectionDialogBox}
          triggerChange={triggerChange}
          edit={edit}
          teamMember={teamMember} // Used to call getTeamMemeber Api for already assigned users
          teamMemberChange={teamMemberChange}
          editTeamMember={editTeamMember}
          editTeamMemberState={editTeamMemberState}
          levelName={levelName}
        />

      </>
    );
  } else {
    return <Loading />;
  }
}
