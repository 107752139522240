

const products = [
    {
        "id": 36,
        "product_id": 145,
        "product_ref": "PD060123010",
        "deal_id": 11,
        "name": "Moving Things and Stuff",
        "description": "This is a product that moves things and stuff",
        "notes": "Move things around and try not to drop them",
        "sbu_id": 26,
        "prod_cat_id": 16,
        "prod_group_id": 6,
        "prod_line_id": 8,
        "channel_id": 59,
        "lines": [
            {
                "manual": true,
                "periodic_code_id": 17,
                "minimum_term": "2",
                "description": "Moving things 101",
                "unit_price": "5",
                "price": 100,
                "quantity": "20",
                "charge_code_id": 5
            },
            {
                "material_id": 13,
                "material_ref": "TS-MAT-156",
                "supplier_id": "42484",
                "supplier_name": "Truck Suppliers",
                "periodic_code_id": 20,
                "minimum_term": "0",
                "quantity": 1,
                "markup": 5,
                "unit_price": 10.5,
                "price": 10.5,
                "options": null,
                "dynamic": false,
                "component_id": 43,
                "description": "Mudgaurds",
                "unit_cost": 10,
                "margin": 5,
                "charge_code_id": 5
            },
            {
                "material_id": 18,
                "material_ref": "Vigi-fmb920",
                "supplier_id": "42603",
                "supplier_name": "VigiTech Solutions Limited",
                "periodic_code_id": 20,
                "minimum_term": "0",
                "quantity": 1,
                "markup": 10,
                "unit_price": 220,
                "price": 220,
                "options": null,
                "dynamic": false,
                "component_id": 44,
                "description": "tektonika fmb920 vehicle tracker",
                "unit_cost": 200,
                "margin": 9,
                "charge_code_id": 5
            },
            {
                "material_id": 18,
                "material_ref": "Vigi-fmb920",
                "supplier_id": "42603",
                "supplier_name": "VigiTech Solutions Limited",
                "periodic_code_id": 20,
                "minimum_term": "0",
                "quantity": 1,
                "markup": 50,
                "unit_price": 45,
                "price": 45,
                "options": null,
                "dynamic": false,
                "component_id": 45,
                "description": "tektonika fmb920 vehicle tracker",
                "unit_cost": 30,
                "margin": 33,
                "charge_code_id": 5
            }
        ],
        "valid_from": "2023-01-04 00:00:00",
        "valid_to": "2024-01-02 13:49:54",
        "approved": true,
        "approved_date": "2023-01-10 13:52:48",
        "selected": true,
        "displayed": true,
        "responsible_id": "75775",
        "active": true
    },
    {
        "id": 38,
        "product_id": 136,
        "product_ref": "PD050822004",
        "deal_id": 11,
        "name": "Trackerbot",
        "description": null,
        "notes": null,
        "sbu_id": 26,
        "prod_cat_id": 16,
        "prod_group_id": 6,
        "prod_line_id": 8,
        "channel_id": null,
        "lines": [
            {
                "manual": true,
                "charge_code_id": 5,
                "periodic_code_id": 18,
                "minimum_term": "12",
                "description": "Subscription per vehicle per month",
                "unit_cost": "3.00",
                "unit_price": 3,
                "price": null
            },
            {
                "manual": true,
                "charge_code_id": 4,
                "periodic_code_id": 20,
                "description": "Fitting charge per vehicle",
                "unit_cost": "15.50",
                "unit_price": 15.5,
                "price": null
            }
        ],
        "valid_from": "2023-01-05 13:57:47",
        "valid_to": "2024-01-17 13:57:54",
        "approved": false,
        "approved_date": null,
        "selected": false,
        "displayed": false,
        "responsible_id": "75775",
        "active": true
    },
    {
        "id": 39,
        "product_id": 140,
        "product_ref": "PD131222009",
        "deal_id": 11,
        "name": "New format PDV2",
        "description": "a system for time and attendance",
        "notes": null,
        "sbu_id": 26,
        "prod_cat_id": 16,
        "prod_group_id": 6,
        "prod_line_id": 7,
        "channel_id": null,
        "lines": [
            {
                "material_id": null,
                "material_ref": null,
                "supplier_id": null,
                "supplier_name": null,
                "periodic_code_id": 18,
                "minimum_term": null,
                "quantity": 1,
                "markup": 20,
                "unit_price": null,
                "price": null,
                "options": [
                    {
                        "material_id": 26,
                        "material_name": "Service One",
                        "material_ref": "JS-004-S",
                        "supplier_id": "638777bb9a501be95f07c902",
                        "material_cost": null,
                        "periodic_code_id": null,
                        "supplier_name": "JamSoft Ltd"
                    },
                    {
                        "material_id": 27,
                        "material_name": "Service Two",
                        "material_ref": "JS-005-S",
                        "supplier_id": "638777bb9a501be95f07c902",
                        "material_cost": null,
                        "periodic_code_id": null,
                        "supplier_name": "JamSoft Ltd",
                        "unit_price": 5.00,
                        "in_basket": true,
                    }
                ],
                "dynamic": true,
                "component_id": 34,
                "description": "Service with options",
                "unit_cost": null,
                "margin": 17,
                "charge_code_id": 5
            },
            {
                "position_id": null,
                "position_name": null,
                "description": "A contract labour rate",
                "quantity": 2,
                "periodic_code_id": 21,
                "markup": 20,
                "unit_price": 133.34,
                "price": 266.68,
                "labour_id": 37,
                "unit_cost": 111.12,
                "margin": 17,
                "charge_code_id": 4
            },
            {
                "manual": true,
                "charge_code_id": 4,
                "periodic_code_id": 16,
                "minimum_term": "2",
                "description": "Annual retainer charge",
                "unit_cost": "33.50",
                "unit_price": 33.5,
                "price": 33.5,
                "quantity": "1"
            }
        ],
        "valid_from": "2023-01-04 00:00:00",
        "valid_to": "2024-01-10 13:58:36",
        "approved": true,
        "approved_date": "2023-01-10 13:58:55",
        "selected": true,
        "displayed": true,
        "responsible_id": "75775",
        "active": true
    }
]

export {products}