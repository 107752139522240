import React, { useEffect, useState } from "react";

import { AppHeaderComponent, AppButtonComponent } from "@unity/components";
import GeneratedForecastTable from "../../modules/SalesOp/components/GeneratedForecastTable";
import FilterTable from "../../common/FilterTable";
import { Link } from "react-router-dom";
import { SalesOpApi } from "@unity/components";
import ForecastChart from "../../modules/SalesOp/components/ForecastChart";

const DUMMY_DATA = {
  id: 1,
  agent_id: 9,
  tenant_uuid: "01f3a545-b3de-4adc-b1d5-36d51c177e5b",
  total: "£100",
  month: "test",
  items: [
    {
      month: "01-2021",
      monthName: "Jan",
      total: "£100",
      items: [
        {
          forecast_id: 1,
          close_date: "09-09-2021",
          value: "£300",
          sales_opp_id: 1,
          name: "Test",
          customer_name: "bob",
        },
        {
          forecast_id: 1,
          close_date: "09-09-2021",
          value: "£34400",
          sales_opp_id: 1,
          name: "Test2",
          customer_name: "tom",
        },
      ],
    },
    {
      month: "01-2021",
      monthName: "feb",
      total: "£200",
      items: [
        {
          forecast_id: 1,
          close_date: "09-09-2021",
          value: "£6700",
          sales_opp_id: 1,
          name: "Test",
          customer_name: "harry",
        },
      ],
    },
  ],
  created_at: "2021-09-14T09:11:55.000000Z",
  updated_at: "2021-09-14T09:11:55.000000Z",
  deleted_at: null,
};

export default function GenerateForecast({ context, history, route }) {
  const [data, setData] = useState();

  const getData = async () => {
    const res = await SalesOpApi.getForecastShow(route.match.params.id);
    setData(res.data);
  };

  useEffect(() => {
    getData();
  }, []);

  if (data) {
    return (
      <>
        <AppHeaderComponent
          context={context}
          theme={context.theme}
          name="forecast"
        />

        <div style={{ paddingTop: 200 }}>
          <p>Total Value: £{data.total}</p>
          <GeneratedForecastTable data={data} context={context} />
        </div>

        <div style={{ paddingTop: 50 }}>
          <ForecastChart data={data} />
        </div>
      </>
    );
  } else {
    return <p>Loading</p>;
  }
}
