import React, { useEffect, useState } from "react";
import { AppHeaderComponent, SalesOpApi, DealBuilderApi } from "@unity/components";
import Loading from "../common/Loading"
import { Link, useHistory } from "react-router-dom";
import ApiLoader from "../common/ApiLoader";
import SalesOpInterface from "../modules/SalesOp/SalesOpInterface";
import ModuleButton from "../common/ModuleButton";
import ModuleFunctions from "../modules/dealModules/ModuleFunctions";
import RequestChangeDialogBox from "../modules/dealModules/RequestChangeDialogBox";

export default function SalesOpEdit({ route, context }) {
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState({ status: false });
  const [activeStep, setActiveStep] = useState(false);
  const [errors, setErrors] = useState(false);
  const [bus, setBus] = useState(false);
  const [busObj, setBusObj] = useState(false); //to get the sbu_id to create a new deal

  const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if page needs to be re-rendered. The value doesn't matter, just the change!

  const [requestChangeDialogBox, setRequestChangeDialogBox] = useState(false);
  const [requestChangeData, setRequestChangeData] = useState(false) //Product data passed to the change request dialog box

  let history = useHistory()

  const openRequestChangeDialogBox = (obj, data) => {
    setRequestChangeData(obj);
    setRequestChangeDialogBox(true);
  }

  const closeRequestChangeDialogBox = () => {
    setRequestChangeData(false);
    setRequestChangeDialogBox(false);
  }

  const handleActiveStep = (e) => {
    setActiveStep(e)
  }

  const busChange = (data) => {
    setBus(data)
    FindUserBus(data)
  }

  const FindUserBus = (data) => {

    data.map(
      e => {
        if (e.name == context.auth.group_name) {
          setBusObj(e)
        }
      }
    )
  }

  const getSalesOpSingle = async () => {
    const res = await SalesOpApi.getSalesOppSingle(route.match.params.id);
    setData(res.data);
  };

  const handleSave = async () => {
    setLoading({ status: true, data: "Loading please wait..." })

    setLoading({ status: true, data: "Save Success please wait..." })
    setTimeout(() => {
      history.push('/leads/index')
    }, 4000)
  }

  const handleNavigation = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const handleUpload = (e) => {
    const newData = { ...data };
    newData['credit_documents'] = e;
    setData(newData);
  };

  const handleNewDeal = async () => {

    const newDealData = {
      sales_opp_id: data.id,
      owner_id: context.auth.contact,
      owner_uuid: context.auth.id,
      name: data.sales_order_ref,
      broker_agent_id: data.agent_id,
      sbu_id: busObj.id
    }
    setLoading({ status: true, data: "Creating your Deal, Please Wait...." });

    const res = await DealBuilderApi.saveDeal(newDealData);

    if (res.data && !res.errors) {
      setLoading({ status: true, data: "Successfully created Deal" });
      setTimeout(() => {
        history.push({
          pathname: `/sales-op/deal/${res.data.id}`,
          state: { hasErrors: false }
        });
        setLoading({ status: false });
      }, 3000);
    } else {
      if (res.errors) {
        setErrors(res.errors);
        setLoading({ status: true, data: "Validation Errors!, Please Wait...." });
        setTimeout(() => {
          setLoading({ status: false });
        }, 3000);
      } else {
        setLoading({ status: true, data: res.message });
        setTimeout(() => {
          //history.push("/deal-builder/index");
          setLoading({ status: false });
        }, 3000);
      }
    }
  };

  const triggerChange = () => {
    setFetchTrigger(fetchTrigger + 1);
  };

  useEffect(() => {
    getSalesOpSingle();
    ModuleFunctions.getBusinesses({ change: busChange });
  }, []);

  if (data && bus) {
    return (
      <>
        <AppHeaderComponent
          context={context}
          theme={context.theme}
          name="sales-op"
          subpage="edit"
          addNew={() => (activeStep < 2)
            ? null
            :
            <ModuleButton
              text="Add Deal"
              btnIcon="add"
              stylename="primary"
              onClick={() => handleNewDeal()}
              style={
                {
                  background: context.theme.top_menu.button_background,
                  color: context.theme.top_menu.button_font,
                }
              }
            />}
        />

        <ApiLoader status={loading.status} data={loading.data} />

        <div style={{ paddingTop: 120, paddingBottom: 40 }}>
          <SalesOpInterface
            data={data}
            setData={setData}
            type="edit"
            route={route}
            bg={context.theme.sidebar.background}
            context={context}
            activeStep={activeStep}
            handleActiveStep={handleActiveStep}
            handleNewDeal={handleNewDeal}
            openRequestChangeDialogBox={openRequestChangeDialogBox}
          />

          <RequestChangeDialogBox
            open={requestChangeDialogBox}
            close={closeRequestChangeDialogBox}
            data={requestChangeData}
            dealData={data}
            triggerChange={triggerChange}
          />

        </div>
      </>

    );
  } else {
    return <Loading />;
  }
}