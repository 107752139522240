import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Loading from "../../../common/Loading";
import { CanReadAll } from "../../../services/Permissions";
import ModuleFunctions from "../../dealModules/ModuleFunctions";
import { products } from "../../../testdata/ProductData";
import CollapsibleTable from "../../dealModules/CollapsibleTable";
import {
  AppButtonComponent,
  CostingsApi,
  SalesOpApi,
  DealBuilderApi,
} from "@unity/components";

export default function FinalDealForm(props) {

    const [data, setData] = useState([]);
    // const [loading, setLoading] = useState({ status: false });
    // const [warning, setWarning] = useState(false);
    // const [warningData, setWarningData] = useState(false);
    const [personnel, setPersonnel] = useState(false);
    const [bus, setBus] = useState(false);
    const [propFocus, setPropFocus] = useState(false);
  
    let history = useHistory();
    const readAll = CanReadAll("sales-op");
  
    const getData = async (obj) => {

      const res = await DealBuilderApi.getContactBasket(obj.site_contact_id);

      // const res = { 
      // data: products,
      // status: 200,
      // }

      if (res.status == 200 || res.status === 201) {
        setData(res.data);
      } else {
        // the request has failed, load an empty array!
        setData([]);
      }
    };
  
    const handleChange = (name, value) => {
      const newData = { ...data };
      newData[name] = value;
      setData(newData);
      setPropFocus(false);
    };
  
    const personnelChange = (data) => {
      setPersonnel(data);
    };
  
    const businessChange = (data) => {
      setBus(data);
    };
  
    const handleLinkClick = (event, id, path) => {
      event.preventDefault();
      history.push({
        pathname: `/sales-op/${path}/${id}`,
      });
    };
  
    useEffect(() => {
      
      ModuleFunctions.getPersonnel({ personnelChange: personnelChange });
      ModuleFunctions.getBusinesses({ change: businessChange });
      if(props.salesData.site_contact_id != null){
        getData(props.salesData);
      }
      //getCodes();
    }, []);

  
    if (data && bus) {
      return (
        <div className="block">
          <div className="container-fluid">
            <h4 style={{ paddingBottom: "10px" }}>Final Deal</h4>

            { data.length <= 0 ?
            <p>No products found</p> :

            <CollapsibleTable
              data={ data.products }
            />
            }

          </div>
        </div>
      );
    } else {
      return <Loading />;
    }
}