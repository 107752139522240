import {
  PersonnelApi,
  ProductsApi,
  DealBuilderApi,
  DocumentVaultAPI,
  CodesApi,
  SuppliersApi,
  SalesOpApi,
} from "@unity/components";

async function getPersonnel(props) {
  const res = await PersonnelApi.getPersonnel();

  if (res.data) {
    props.personnelChange(res.data);
  }
}

async function getSalesOpps(props) {
  const res = await SalesOpApi.getSalesOpps();

  if (res.data) {
    props.salesOppChange(res.data);
  }
}

async function getBusinesses(props) {
  const res = await CodesApi.getBusinessUnits();
  if (res.data) {
    props.change(res.data);
  }
}

async function getStructure(props) {
  const res = await ProductsApi.getStructure();

  if (res.data) {
    props.structureChange(res.data);
  }
}

async function getPeriodicCodes(props) {
  const res = await CodesApi.getPeriodicCodes();
  if (res.data) {
    props.periodsChange(res.data);
  }
}

async function getChargeCodes(props) {
  const res = await CodesApi.getChargeCodes();

  if (res.data) {
    props.chargesChange(res.data);
  }
}

async function getPaymentMethods(props) {
  const res = await CodesApi.getPaymentMethods();

  if (res.data) {
    props.methodsChange(res.data);
  }
}

async function getVatRates(props) {
  const res = await CodesApi.getVatRates();

  if (res.data) {
    props.vatsChange(res.data);
  }
}

async function getDeal(props) {
  const res = await DealBuilderApi.getDeal(props.id);
  if (res.data) {
    props.dealChange(res.data);
  }
}

async function getDealVersion(props) {
  const res = await DealBuilderApi.getVersions(props.ref);
  if (res.data) {
    props.versionChange(res.data);
  }
}

async function getSupplierName(props) {
  const res = await SuppliersApi.getSupplierSingle(props.id);

  if (res.data) {
    props.componentChange({
      supplier_name: res.data.name,
    });
  }
}

function getCodeName(props) {
  if (Array.isArray(props.codesArray)) {
    const obj = props.codesArray.find((element) => element.id === props.id);

    if (typeof obj !== "undefined") {
      return obj.name;
    }
  }
  return "undefined";
}

async function handleView(props) {
  const res = await DocumentVaultAPI.generateTempLink(props.id);
  window.open(res.data, "_blank");
}

function handleDealChange(props) {
  const newData = {};
  newData[props.name] = props.value;
  newData["changed"] = true;

  props.dealChange(newData);
}

function handleOwnerChange(props) {
  props.dealChange({
    changed: true,
    owner_id: props.event.target.value,
    owner_uuid: props.element.props.personObj.uuid,
    owner_name: props.element.props.personObj.name,
  });
}

function testReason(props) {
  let issues = 0;
  const newErrs = {};

  if (!props.reason) {
    newErrs["reason"] = "You must give a approval rejection reason!";
    issues += 1;
  }

  return issues > 0 ? newErrs : false;
}

/**
 * This function will set the structure states in the product overview.
 *
 * @param   {*}         props       has the elements:
 * @param   {object}    data        the current product
 * @param   {object}    structure   the product structure
 * @function    prodCatsChange      to update the product categories state
 * @function    groupsChange         to update the product groups state
 * @function    linesChange         to update the product lines state
 */

function setCurrentProductStructure(props) {
  const cats = props.structure.find(
    (element) => element.id === props.data.sbu_id
  );
  props.prodCatsChange(cats[cats.children_array]);

  if (props.data.prod_cat_id) {
    const groups = cats[cats.children_array].find(
      (element) => element.id === props.data.prod_cat_id
    );
    props.groupsChange(groups[groups.children_array]);

    if (props.data.prod_group_id) {
      const lines = groups[groups.children_array].find(
        (element) => element.id === props.data.prod_group_id
      );
      props.linesChange(lines[lines.children_array]);
    }
  }
}

export default {
  getPersonnel,
  getSalesOpps,
  getBusinesses,
  getStructure,
  getPeriodicCodes,
  getChargeCodes,
  getPaymentMethods,
  getVatRates,
  getDeal,
  getSupplierName,
  getCodeName,
  handleView,
  handleDealChange,
  handleOwnerChange,
  testReason,
  setCurrentProductStructure,
  getDealVersion,
};
