import React, { useEffect, useState } from "react";
import ProductLines from "../modules/dealModules/ProductLines";
import {
  DealBuilderApi,
  AppHeaderComponent,
  LoadingComponent,
  ApiLoaderComponent,
  DocCreatorApi,
  SalesOpApi
} from "@unity/components";
import {
  CanCreate,
  CanRead,
  CanReadAll,
  CanUpdate,
  CanModuleAdmin,
  CanDelete,
} from "../services/Permissions";
import ModuleFunctions from "../modules/dealModules/ModuleFunctions";
import ProductOverview from "../modules/dealModules/ProductOverview";
import SupportingProductDoc from "../modules/dealModules/SupportingProductDoc";
// import DatePicker from "../common/DatePicker";
import FullScreenDialogBox from "../modules/dealModules/FullScreenDialogBox";
import OptionalDocsDialogBox from "../modules/dealModules/OptionalDocsDialogBox";
import SaveWarningDialogBox from "../common/SaveWarningDialog";
import { TextField } from "@material-ui/core";
import Loading from "../common/Loading";
import Currency from "currency.js";


export default function SalesOpProduct({ route, context }) {
  const [loading, setLoading] = useState({ status: false });
  const [data, setData] = useState(false);
  const [errors, setErrors] = useState(false);
  const [periods, setPeriods] = useState(false);
  const [charges, setCharges] = useState(false);
  const [dataLog, setDataLog] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(0); // This is used to determine if page needs to be re-rendered. The value doesn't matter, just the change!

  const [bus, setBus] = useState(false);
  const [salesOpp, setSalesOpp] = useState(false);

  const [openFullBox, setOpenFullBox] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [productLineIndex, setProductLineIndex] = useState(false);
  const [optionLineIndex, setOptionLineIndex] = useState(false);
  const [saveWarningDialogBox, setSaveWarningDialogBox] = useState(false);

  const [optionalDocumentDialogBox, setOptionalDocumentDialogBox] = useState(false);
  const [optionalDocData, setOptionalDocData] = useState(false);

  const [liveProductDocument, setLiveProductDocument] = useState(false);
  const [liveProductSchedules, setLiveProductSchedules] = useState(false);

  const [structure, setStructure] = useState(false);

  const [associatedUser, setAssociatedUser] = useState(false)

  const [dealOwner, setDealOwner] = useState(false)

  const currentDate = new Date();
  const edit = CanUpdate("sales-op");

  console.log("data ", data)
  const uneditable = !dealOwner && !associatedUser && !data.displayed;

  console.log("data ", uneditable)


  const handleCheckBox = (data) => {
  };

  const triggerChange = () => {
    setFetchTrigger(fetchTrigger + 1);
  };


  const calculateProductLineMarkup = (index , event ) => {

    const GBP = value => Currency(value, { symbol: '£', decimal: '.', separator: ',' });
    const newData = { ...data };

    const value = (event.target.value.indexOf(".") >= 0)
        ? (event.target.value.substr(0, event.target.value.indexOf(".")) + event.target.value.substr(event.target.value.indexOf("."), 3))
        : event.target.value;

    newData.lines[index][event.target.name] = value

    const cost = GBP(newData.lines[index]['unit_price']);
    const total = GBP(newData.lines[index]['price']);

    const markup = GBP(cost).multiply((parseFloat(newData.lines[index]['quantity']) || 0));
    const work = GBP(cost).add(markup);

    newData.lines[index]['price'] = markup.value;
    
    dealChange({ ...newData , changed: true });    
    }



  const calculateMarkup = (event, index ) => {

    const GBP = value => Currency(value, { symbol: '£', decimal: '.', separator: ',' });
    const newData = { ...data };

    const value = (event.target.value.indexOf(".") >= 0)
        ? (event.target.value.substr(0, event.target.value.indexOf(".")) + event.target.value.substr(event.target.value.indexOf("."), 3))
        : event.target.value;

    newData.lines[productLineIndex].options[index][event.target.name] = value

    const cost = GBP(newData.lines[productLineIndex].options[index]['material_cost']);
    const total = GBP(newData.lines[productLineIndex].options[index]['unit_price']);

    const markup = GBP(cost).multiply((parseFloat(newData.lines[productLineIndex].options[index]['markup']) || 0) / 100);
    const work = GBP(cost).add(markup);

    newData.lines[productLineIndex].options[index]['unit_price'] = work.value;
    
    dealChange({ ...newData , changed: true });    
    }

  const handleDynamicLine = (cost, index) => {
    const dataCopy = { ...data };
    dataCopy.lines[productLineIndex].options[index][cost.target.name] =
      cost.target.valueAsNumber;
    dealChange({ ...dataCopy, changed: true });
  };

  const handleOpenSaveWarningDialogBox = () => {
    setSaveWarningDialogBox(true);
  };

  const handleCloseSaveWarningDialogBox = () => {
    setSaveWarningDialogBox(false);
  };

  const deleteOptionalDocument = (index) => {
    const dataCopy = { ...data };
    dataCopy.lines[productLineIndex].options[
      optionLineIndex
    ].option_docs.splice(index, 1);

    dealChange({ ...dataCopy, changed: true });
  };

  const handleOpenOptionalDocumentDialogBox = (index) => {
    setOptionalDocData(
      data.lines[productLineIndex].options[index]["option_docs"]
    );
    setOptionLineIndex(index);
    setOpenFullBox(false);
    setOptionalDocumentDialogBox(true);
  };

  const handleCloseOptionalDocumentDialogBox = () => {
    setOptionalDocumentDialogBox(false);
    setOptionLineIndex(false);
    setOpenFullBox(true);
    setOptionalDocData(false);
  };

  const closeFullBox = () => {
    setProductLineIndex(false);
    setOpenFullBox(false);
  };

  const handleFullBox = (index) => {
    setProductLineIndex(index);
    setSelectedRow(data.lines[index]);
    setOpenFullBox(true);
  };

  const chargesChange = (data) => {
    setCharges(data);
  };

  const periodsChange = (data) => {
    setPeriods(data);
  };

  const busChange = (data) => {
    setBus(data);
  };

  const salesOppChange = (data) => {
    setSalesOpp(data);
  };

  const structureChange = (data) => {
    setStructure(data);
  };

  const dealChange = (data) => {
    setData((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const getPeriodicData = (pCode) => {
    const result = periods.find((x) => x.id == pCode);
    if (result) {
      return result.name;
    }
    return "N/A";
  };

  const getChargeData = (cCode) => {
    const cResult = charges.find((x) => x.id == cCode);
    if (cResult) {
      return cResult.name;
    }
    return "N/A";
  };

  const getProduct = async () => {
    const res = await DealBuilderApi.getLiveProduct(route.match.params.id);

    if (res.status == 200 || res.status == 201) {
      setData({ ...res.data, dealChange: dealChange });
      getProductDocument(res.data.id, res.data);
      getProductSchedules(res.data.id, res.data);
      getResponsePerson(res.data.deal_id, res.data);
    }
  };

  const getResponsePerson = async (id, obj) => {

    const res1 = await DealBuilderApi.getDeal(id);

    if (res1.status == 200 || res1.status == 201) {

if(res1.data.broker_id != null || res1.data.broker_id == context.auth.contact){
  setDealOwner(true)
}

      const res2 = await SalesOpApi.getSalesOppSingle(res1.data.sales_opp_id);

      if( res2.errors == null && (res2.data.associated_id != null || res2.data.associated_id != undefined) ){
      
          if(context.auth.agent_id == res2.data.associated_id){ setAssociatedUser(true) }
          else{setAssociatedUser(false)}
      } else{
        setAssociatedUser(false)
      }
    } else{
      setAssociatedUser(false)
    }

  }

  const getProductDocument = async (id, obj) => {
    const res = await DealBuilderApi.liveProductGetDocuments(id, obj);
    if (res.status == 200 || res.status == 201) {
      setLiveProductDocument(res.data);
    }
  };

  const getProductSchedules = async (id, obj) => {
    const res = await DealBuilderApi.liveProductGetSchedules(id, obj);
    if (res.status == 200 || res.status == 201) {
      setLiveProductSchedules(res.data);
    }
  };

  const getProductLog = async () => {
    const res = await DealBuilderApi.getLiveProductApprovedLog(route.match.params.id);

    if (res.status == 200 || res.status == 201) {
      setDataLog(res.data);
    }
  };

  const updateLine = (index, obj) => {
    let tempProduct = { ...data };
    tempProduct.lines[index][obj.target.name] = parseFloat(obj.target.value);
    dealChange({ ...tempProduct, changed: true });
  };

  const updateValidToDynamicDealDate = (obj, index) => {
    const dataCopy = { ...data };
    let lowest = new Date(obj.$d);

    dataCopy.lines[productLineIndex].options[index]["valid_to"] = new Date(
      obj.$d
    );

    dataCopy.lines[productLineIndex].options.forEach((e) => {
      let dateObj = new Date(e.valid_to);
      if (dateObj < lowest) {
        lowest = dateObj;
      }
    });

    dataCopy["valid_to"] = lowest;

    dealChange({ ...dataCopy, changed: true });
  };

  const updateValidFromDynamicDealDate = (obj, index) => {
    const dataCopy = { ...data };
    let highest = new Date(obj.$d);

    dataCopy.lines[productLineIndex].options[index]["valid_from"] = new Date(
      obj.$d
    );

    dataCopy.lines[productLineIndex].options.forEach((e) => {
      let dateObj = new Date(e.valid_from);
      if (dateObj > highest) {
        highest = dateObj;
      }
    });

    dataCopy["valid_from"] = highest;

    dealChange({ ...dataCopy, changed: true });
  };

  const handleSave = async () => {
    setLoading({ status: true, data: "Saving Product, Please Wait...." });

    const res = await DealBuilderApi.updateLiveProduct(
      route.match.params.id,
      data,
      "builder"
    );

    if (res.data && res.errors == null ) {
      setLoading({ status: true, data: "Successfully updated product" });
      setTimeout(() => {
        getProduct();
        triggerChange();
        setLoading({ status: false });
      }, 3000);
      
    } else {
      if (res.errors) {
        setErrors(res.errors);
        setLoading({
          status: true,
          data: "Validation Errors!, Please Wait....",
        });
        setTimeout(() => {
          setLoading({ status: false });
        }, 3000);
      } else {
        setLoading({ status: true, data: res.message });
        setTimeout(() => {
          //history.push("/deal-builder/index");
          setLoading({ status: false });
        }, 3000);
      }
    }
     triggerChange()
  };


  const updateValidToDealDate = (date) => {
    let selectedDate = { ...data };
    const formatedDate = new Date(date)

    if(selectedDate.valid_to == null){
      selectedDate["valid_to"] = formatedDate;
    }
    else if( selectedDate.valid_to != null && selectedDate.valid_to < formatedDate ){
      selectedDate["valid_to"] = formatedDate;
    }

    dealChange({ ...selectedDate , changed: true });
  }


  const updateValidFromDealDate = (date) => {
    let selectedDate = { ...data };
    const formatedDate = new Date(date)

    if(selectedDate.valid_from == null){
      selectedDate["valid_from"] = formatedDate;
    }
    else if( selectedDate.valid_from != null && selectedDate.valid_from > formatedDate ){
      selectedDate["valid_from"] = formatedDate;
    }

    dealChange({ ...selectedDate , changed: true });
  }


  const convertDate = (inDate) => {
    if(inDate != null){
      let newDate = inDate.toLocaleString()
      let splicedDate = newDate.slice(0, 10) 
      
      return splicedDate.split("/").reverse().join('-')

    }
    else{
      return ""
    }
  }


  useEffect(() => {
    ModuleFunctions.getStructure({ structureChange: structureChange });
    getProduct();
    getProductLog();
    ModuleFunctions.getPeriodicCodes({ periodsChange: periodsChange });
    ModuleFunctions.getChargeCodes({ chargesChange: chargesChange });
    ModuleFunctions.getSalesOpps({ salesOppChange: salesOppChange });
    ModuleFunctions.getBusinesses({ change: busChange });
  }, [ fetchTrigger ]);

  if (data && structure && periods && bus) {
    return (
      <>
        <AppHeaderComponent
          saveBtn={data.changed ? handleSave : null}
          context={context}
          theme={context.theme}
          name="sales-op"
          subpage="Product"
          spacer={true}
          unsavedItems={data.changed}
          showUnsavedWarningPromt={handleOpenSaveWarningDialogBox}
        />

        <ProductOverview
          data={data}
          structure={structure}
          type="view"
          bus={bus}
        />

        <div className="block">
          <div className="container-fluid">
            <div style={{ width: "30%" }}>
              <TextField
                style={{ marginBottom: "20px" }}
                label="Valid From"
                name="valid_from"
                value={ convertDate(data.valid_from) }
                size="small"
                type="date"
                fullWidth
                onChange={ (e) => updateValidFromDealDate(e.target.value) }
                //InputProps={{ readOnly: true }}
              ></TextField>

              <TextField
                label="Valid To"
                name="valid_to"
                //value={ convertDate(data.valid_to) }
                value={ convertDate(data.valid_to) }
                size="small"
                type="date"
                fullWidth
                onChange={ (e) => updateValidToDealDate(e.target.value) }
                //InputProps={{ readOnly: true }}
              ></TextField>

              <br></br>
            </div>
          </div>
        </div>

        <ProductLines
          productLines={data.lines}
          handleFullBox={handleFullBox}
          updateLine={updateLine}
          handleCheckBox={handleCheckBox}
          isDealApproved={data.deal_approval}
          errors={errors}
          uneditable={uneditable}
          getPeriodicData={getPeriodicData}
          calculateProductLineMarkup={calculateProductLineMarkup}
        />

        {liveProductDocument && liveProductSchedules ? (
          <SupportingProductDoc
            productDocuments={liveProductDocument}
            productSchedules={liveProductSchedules}
            calculateMarkup={calculateMarkup}
          />
        ) : null}

        <FullScreenDialogBox
          data={selectedRow}
          dealChange={dealChange}
          getChargeData={getChargeData}
          getPeriodicData={getPeriodicData}
          closeFullBox={closeFullBox}
          openFullBox={openFullBox}
          handleDynamicLine={handleDynamicLine}
          updateValidFromDynamicDealDate={updateValidFromDynamicDealDate}
          updateValidToDynamicDealDate={updateValidToDynamicDealDate}
          productLineIndex={productLineIndex}
          type={"builder"}
          handleOpenOptionalDocumentDialogBox={
            handleOpenOptionalDocumentDialogBox
          }
          calculateProductLineMarkup={calculateProductLineMarkup}
          uneditable={uneditable}
          context={context}
        />

        <SaveWarningDialogBox
          open={saveWarningDialogBox}
          close={handleCloseSaveWarningDialogBox}
        />

        <OptionalDocsDialogBox
          open={optionalDocumentDialogBox}
          close={handleCloseOptionalDocumentDialogBox}
          data={optionalDocData}
          deleteOptionalDocument={deleteOptionalDocument}
          optionLineIndex={optionLineIndex}
          type="builder"
        />

        <ApiLoaderComponent status={loading.status} data={loading.data} />
      </>
    );
  } else {
    return <Loading />;
  }
}
