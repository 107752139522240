import React, { useEffect, useState } from "react";

import Loading from "../common/Loading";
import { AppHeaderComponent, LeadsApi, AppButtonComponent } from "@unity/components";
import LeadsForm from "../modules/leads/LeadsForm";
import { IsModuleEnabled } from "../services/Permissions";
import { Link } from "react-router-dom";

export default function SalesOpView({ context, route }) {

  const [data, setData] = useState(false);
  const getLeadSingle = async () => {
    const res = await LeadsApi.showLeads(route.match.params.id);
    setData(res.data);
  };

  const handleUpload = (e) => { };
  const handleChange = (name, value) => { };

  useEffect(() => {
    getLeadSingle();
  }, []);

  if (data) {
    return (
      <>
        <AppHeaderComponent context={context} theme={context.theme} name="leads" />
        <div style={{ paddingTop: 120, paddingBottom: 40 }}>
          {IsModuleEnabled('activities') && <Link to={`/activities/lead/${route.match.params.id}/index`}><AppButtonComponent text="view activities" btnIcon={"visibility"} style={{ background: context.theme.sidebar.background, color: 'white', marginLeft: 20 }} /></Link>}
          <LeadsForm
            data={data}
            type="view"
            handleChange={handleChange}
            handleUpload={handleUpload}
          />
        </div>
      </>

    );
  } else {
    return <Loading />;
  }
}
