import React, { useState } from "react";
import FilterTable from "../../../common/FilterTable";
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  makeStyles,
  IconButton,
  Box,
  TextField,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { AppHeaderComponent, AppButtonComponent } from "@unity/components";
import { Link, useHistory } from "react-router-dom";
import AppButton from "../../../common/AppButton";

export default function GeneratedForecastTable(props) {
  const useStyles = makeStyles({
    table: {
      minWidth: 70,
    },
  });

  const classes = useStyles();

  const data = props.data.items;

  const columns = [
    {
      name: "month_name",
      label: "Month",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return data[dataIndex].month_name + " " + data[dataIndex].month;
        },
      },
    },
    { name: "value", label: "Weighted Value" },
  ];

  const options = {
    filterType: "dropdown",
    responsive: "stacked",
    print: true,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const columns = [
        {
          name: "name",
          label: "Item Name",
        },
        { name: "company_name", label: "Customer" },
        {
          name: "value",
          label: "Weighted Value",
        },
        {
          name: "",
          label: "Action",
          options: {
            customBodyRenderLite: (dataIndex) => {
              return (
                <>
                  <Link
                    to={{
                      pathname: `/sales-op/edit/${
                        data[rowMeta.rowIndex].items[dataIndex].sales_opp_id
                      }`,
                    }}
                  >
                    <AppButton text="View" btnIcon="visibility" />
                  </Link>
                </>
              );
            },
          },
        },
      ];

      const options = {};

      return (
        <>
          <td colSpan={4}>
            <FilterTable
              columns={columns}
              data={data[rowMeta.rowIndex].items}
              options={options}
            />
          </td>
        </>
      );
    },
  };

  return (
    <>
      <FilterTable columns={columns} data={data} options={options} />
    </>
  );
}
