import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


function Row(props) {
  
  const { row } = props;
  const [open, setOpen] = React.useState(false);


  const dyanmicPrice = (obj , index) => {

    const selectedOption = obj.options.filter(
      (e) => e.in_basket
    )
    return(parseInt(selectedOption[0].unit_price))
  }

  const dyanmicName = (obj , index) => {
    const selectedOption = obj.options.filter(
      (e) => e.in_basket
    )
    return(selectedOption[0].material_name)
  }

  const dyanmicPriceTotal = (obj , index) => {

    const selectedOption = obj.options.filter(
      (e) => e.in_basket
    )
    return(obj.quantity * parseInt(selectedOption[0].unit_price))
  }


  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{row.name}</TableCell>
        <TableCell align="right">{row.valid_from}</TableCell>
        <TableCell align="right">{row.valid_to}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Total price (£)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.lines.map((row , index) => (

                    <TableRow key={row.date}>
                      <TableCell component="th" scope="row">
                        {row.dynamic ? dyanmicName(row, index) : row.description}
                      </TableCell>
                      <TableCell>{ row.dynamic ? dyanmicPrice(row , index) : row.unit_price}</TableCell>
                      <TableCell align="right">{row.quantity || ""}</TableCell>
                      <TableCell align="right">
                        {/* {Math.round(historyRow.amount * row.price * 100) / 100} */} 
                        { row.dynamic ? dyanmicPriceTotal(row , index) : row.price || row.unit_price || ""}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


export default function CollapsibleTable(props) {

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Name</TableCell>
            <TableCell align="right">Price</TableCell>
            <TableCell align="right">Valid From</TableCell>
            <TableCell align="right">Valid To</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row , index) => (
            <Row key={row.name} row={row} prodIndex={index}/>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}